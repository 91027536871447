/*eslint-disable*/
import React, { useState, useEffect } from "react";
// @material-ui/core components
import {
  TextField,
  makeStyles,
  CircularProgress,
  MenuItem,
  Tooltip,
  IconButton,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@material-ui/core";
import Swal from "sweetalert2";
import {
  successAlert,
  errorAlert,
  msgAlert,
} from "views/LDocs/Functions/Functions";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import jwt from "jsonwebtoken";
// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles2 from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { useDispatch, useSelector } from "react-redux";
import { setIsTokenExpired } from "actions";
import Refresh from "@material-ui/icons/Refresh";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(styles);
const sweetAlertStyle = makeStyles(styles2);

export default function AddAccount(props) {
  // register/view/edit form
  const { AccDetail, Updating } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem("im-mate");
  const { enterpriseDetails } = useSelector((state) => state.userReducer);

  const [formState, setFormState] = useState({
    controlAccount: '',
    accountTypeID: '',
    accountType: '',
    accountParentName: '',
    name: '',
    code: '',
    acctualCode: '',
    description: '',
    taxId: '',
    level: '',
    errors: {
      accountType: '',
      name: '',
      code: '',
    },
  });
  const [accountCode, setAccountCode] = useState('');
  const [controlAccounts, setControlAccounts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [loading, setLoading] = useState(false);
  const userDetails = jwt.decode(Token);
  let ID = '';

  useEffect(() => {
    getAccountList();
    getTaxList();
  }, []);

  // FOR EDIT OR VIEW ACCOUNT
  useEffect(() => {
    if (Updating !== null && AccDetail !== null) {
      setFormState((formState) => ({
        ...formState,
        controlAccount: AccDetail.controlAccount,
        accountTypeID: AccDetail.accountTypeID,
        accountType: AccDetail.accountType,
        accountParentName: AccDetail.accountParentName,
        name: AccDetail.name,
        code: AccDetail.code,
        acctualCode: AccDetail.acctualCode,
        description: AccDetail.description,
        taxId: AccDetail.taxId,
        level: AccDetail.level,
      }));
    }

  }, [Updating, AccDetail]);

  // FOR SELECTING BYDEFAULT NOTAX
  useEffect(() => {
    if (taxList?.length > 0 && Updating === null) {
      let taxId = taxList[0]?._id;
      setFormState((formState) => ({
        ...formState,
        ['taxId']: taxId,
      }));
    }
  }, [taxList])

  // FOR CONTROL ACCOUNT ARRAY MANIPULATION
  useEffect(() => {
    let array = [];
    if (controlAccounts?.length > 0) {
      controlAccounts?.forEach((acct) => {
        acct?.List?.forEach((list) => {
          const parentNames = list.parents.flatMap((parent) => parent?.name);
          array.push({
            accountParentName: acct.parentName,
            accountType: list._id,
            accountTypeID: list.AccParentId,
            account: `${acct.parentName} > ${parentNames.reverse().join(' > ')} ${parentNames.length > 0 ? '>' : ''} ${list.code} - ${list.name}`
          });
        });
      });
      setAccounts(array);
    }
  }, [controlAccounts]);

  //Handle Change
  const handleChange = event => {
    const { name, value } = event.target;
    if (name === 'code') {
      let limit = enterpriseDetails?.coaCodeLength - accountCode.length
      if (limit === value.length - 1) {

      } else {
        let fullValue = accountCode + value;
        setFormState((formState) => ({
          ...formState,
          ['acctualCode']: fullValue,
          ['code']: value,
        }));
      }
    }
    else {
      setFormState((formState) => ({
        ...formState,
        [name]: value,
      }));
    }
  }

  const getTaxList = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL}/lov/getTaxList`,
        { headers: { authorization: Token } })
      setTaxList(data);
    } catch (error) {
      if (error.response) {
        error.response.status == 401 && dispatch(setIsTokenExpired(true));
      }
      let msg =
        typeof error.response != "undefined"
          ? error.response.data
          : error.message;
      errorAlert(msg);
    }
  }

  const getAccountList = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL}/lov/getAccountTypes`,
        { headers: { authorization: Token } })
      setControlAccounts(data?.Acc);
    } catch (error) {
      if (error.response) {
        error.response.status == 401 && dispatch(setIsTokenExpired(true));
      }
      let msg =
        typeof error.response != "undefined"
          ? error.response.data
          : error.message;
      errorAlert(msg);
    }
  }

  const getAccountCodes = async (id) => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL}/chartsofAccounts/getSubAccById/${id}`,
      headers: { authorization: Token },
    }).then(response => {
      setAccountCode(response.data.code);
    })
      .catch(error => {
        errorAlert(error?.response?.data?.msg)
      })
  }

  const saveAccount = () => {
    setLoading(true);
    let
      accountType,
      name,
      code
    const Check = require("is-null-empty-or-undefined").Check;
    var error = false;

    if (!Check(formState.controlAccount)) {
      accountType = "success";
    } else {
      accountType = "error";
      error = true;
    }
    if (!Check(formState.name)) {
      name = "success";
    } else {
      name = "error";
      error = true;
    }
    if (!Check(formState.code)) {
      code = "success";
    } else {
      code = "error";
      error = true;
    }
    setFormState((formState) => ({
      ...formState,
      errors: {
        ...formState.errors,
        accountType,
        name,
        code
      },
    }));
    if (error) {
      return false;
    } else {
      var bodyFormData = {
        chartId: AccDetail?._id ? AccDetail?._id : '',
        Archive: false,
        controlAccount: formState.controlAccount,
        accountTypeID: formState.accountTypeID,
        accountType: formState.accountType,
        accountParentName: formState.accountParentName,
        name: formState.name,
        code: formState.code,
        acctualCode: formState.acctualCode,
        description: formState.description,
        taxId: formState.taxId,
        level: formState.level,
      };
      let msg = "";
      axios({
        method: "post",
        url: Updating === true ? `${process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL}/chartsofAccounts/updateChartOfAccounts`
          : `${process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL}/chartsofAccounts/addChartofAccount`,
        data: bodyFormData,
        headers: { authorization: Token }
      })
        .then((response) => {
          props.getCOA();
          setLoading(false);
          closeModal();
          if (Updating) {
          msg = "Account Updated Successfully!"; 
          } else {
            msg = "Account Added Successfully!";
          }
          successAlert(msg);
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true));
          }
          msg =
            typeof error.response != "undefined"
              ? error.response.data
              : error.message;
          closeModal();
          errorAlert(msg);
        });
    }
  };

  const closeModal = () => {
    setFormState((formState) => ({
      ...formState,
      controlAccount: '',
      accountTypeID: '',
      accountType: '',
      accountParentName: '',
      name: '',
      code: '',
      acctualCode: '',
      description: '',
      taxId: '',
      level: '',
      errors: {
        accountType: '',
        name: '',
        code: '',
      },
    }));
    setAccountCode('')
    props.closeModal();
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info" icon>
            <CardIcon color="info">
              <h4 className={classes.cardTitle}>{Updating ? 'Edit' : Updating !== null ? 'View' : 'Add'} Account</h4>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <form>
              <GridContainer>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accounts || []}
                    size='small'
                    value={formState.controlAccount || ''}
                    getOptionLabel={option => typeof (option) === 'string' ? option : option?.account}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        getAccountCodes(newValue?.accountType);
                        setFormState((formState) => ({
                          ...formState,
                          ['controlAccount']: newValue?.account,
                          ['accountParentName']: newValue?.accountParentName,
                          ['accountType']: newValue?.accountType,
                          ['accountTypeID']: newValue?.accountTypeID,
                        }));
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaAccount'
                        required
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        error={formState.errors.accountType === 'error'}
                        helperText={
                          formState.errors.accountType === 'error'
                            ? 'Account Type is required'
                            : null
                        }
                        value={formState.controlAccount || ''}
                        disabled={Updating !== null}
                        label='Account Type'
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    required
                    error={formState.errors.code === 'error'}
                    helperText={
                      formState.errors.code === 'error'
                        ? `Code is required or must be ${enterpriseDetails?.coaCodeLength} digits`
                        : null
                    }
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black' },
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    disabled={Updating !== null}
                    label={`Code (Must be ${enterpriseDetails?.coaCodeLength} Digits)`}
                    name='code'
                    type='number'
                    onChange={event => {
                      handleChange(event)
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ padding: '0px' }}>
                          {accountCode}
                        </InputAdornment>
                      ),
                    }}
                    value={formState.code || ''}
                  ></TextField>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <TextField
                    fullWidth={true}
                    className={classes.textField}
                    required
                    error={formState.errors.name === 'error'}
                    helperText={
                      formState.errors.name === 'error'
                        ? 'Name is required'
                        : null
                    }
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black' },
                      classes: {
                        asterisk: classes.asterisk
                      }
                    }}
                    disabled={Updating === false}
                    label='Account Title'
                    name='name'
                    type='text'
                    onChange={event => {
                      handleChange(event)
                    }}
                    value={formState.name || ''}
                  />
                </GridItem>
                <GridItem
                  xs={10}
                  sm={10}
                  md={6}
                  lg={6}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    size='small'
                    InputLabelProps={{
                      shrink: true,
                      style: { color: 'black' },
                    }}
                    disabled={Updating === false}
                    select
                    label='Tax'
                    name='taxId'
                    type='text'
                    onChange={event => {
                      handleChange(event)
                    }}
                    value={formState.taxId || ''}
                  >
                    <MenuItem>Choose Tax</MenuItem>
                    {taxList.map((list, index) => (
                      <MenuItem key={index} value={list._id}>
                        {list.displayName}
                      </MenuItem>
                    ))}
                  </TextField>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <TextField
                    className={classes.textField}
                    fullWidth={true}
                    disabled={Updating === false}
                    size='small'
                    label='Description'
                    name='description'
                    type='text'
                    onChange={event => {
                      handleChange(event)
                    }}
                    value={formState.description || ''}
                  />
                </GridItem>
              </GridContainer>
              <Button
                color="danger"
                className={classes.registerButton}
                onClick={closeModal}
                round
              >
                Close
              </Button>
            </form>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
