import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Divider, TextField } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);

export default function AccountPayable({ accountList, state, setState, saveCOADefault,isLoading }) {
  const classes = useStyles();

  const handleChange = (name, value) => {
    let data = {
      id: value._id,
      name: value.name,
      code: value.code
    }
    setState((state) => ({
      ...state,
      [name]: data,
    }));
  }


  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <h4 className={classes.cardTitleText} style={{ margin: 0 }}> Pre-Payment / Advance Payments</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Expenses') || []}
                    size='small'
                    value={state.prePayExp || ''}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('prePayExp', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Expense Account'
                        variant='standard'
                        // error={state.prePayExp?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.prePayExp}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <Divider />
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Liabilities') || []}
                    size='small'
                    value={state.prePayLib || ''}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('prePayLib', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Liability Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.prePayLib || ''}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <h4 className={classes.cardCategory} style={{ margin: 0 }}>
                  With Receipt Invoices
                </h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Expenses') || []}
                    size='small'
                    value={state.receiptExp}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('receiptExp', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Expense Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.receiptExp}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <Divider />
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Liabilities') || []}
                    size='small'
                    value={state.receiptLib}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('receiptLib', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Liability Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.receiptLib}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <h4 className={classes.cardCategory} style={{ margin: 0 }}>
                  Standard / Petty Cash Invoices
                </h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              {" "}
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Expenses') || []}
                    size='small'
                    value={state.pettyCashExp}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('pettyCashExp', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Expense Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.pettyCashExp}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <Divider />
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Liabilities') || []}
                    size='small'
                    value={state.pettyCashLib}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('pettyCashLib', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Liability Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.pettyCashLib}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <Button
        color="danger"
        className={classes.registerButton}
        style={{ float: "right", marginTop: 20 }}
        round
        type="button"
        onClick={() => saveCOADefault("payable")}
      >
        {isLoading ? <CircularProgress/> : "Update"}
      </Button>
    </>
  );
}
