import React, { useState, useEffect } from 'react'
// @material-ui/icons
import { makeStyles, TextField, Typography } from '@material-ui/core'
// core components
import { Animated } from 'react-animated-css'
import GridContainer from 'components/Grid/GridContainer.js'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card
} from '@material-ui/core'
import { addZeroes } from 'views/LDocs/Functions/Functions'

// style for this view
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(styles)
const sweetAlertStyle = makeStyles(styles2)
const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    maxHeight: 500,
    position: 'relative',
    maxWidth: 360
  },
  listSection: {
    backgroundColor: 'inherit'
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0
  },
  table: {
    minWidth: '100%',
    border: 1
  },
  TableCell: {
    minWidth: '10%',
    alignContent: 'right'
  },
  TableID: {
    maxWidth: '3%'
  },
  TableRow: {
    cursor: 'pointer',
    background: 'white',
    border: 1,
    width: '100%'
  }
}))

export default function Step1(props) {
  const classes = useStyles()
  const classesList = useStyle()
  const history = useHistory()
  const isAr =
    history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    ) == 'ar'
      ? true
      : false
  const [animateStep, setAnimateStep] = useState(true)
  return (
    <Animated
      animationIn='bounceInRight'
      animationOut='bounceOutLeft'
      animationInDuration={1000}
      animationOutDuration={1000}
      isVisible={animateStep}
    >
      <GridContainer>
        <Table className={classesList.table} aria-label='simple table'>
          <TableHead>
            <TableRow className={classesList.TableRow}>
              <TableCell className={classesList.TableID}>ID</TableCell>
              <TableCell>Name</TableCell>
              {isAr ? (
                <>
                  <TableCell className={classesList.TableCell}>
                    Asset Account
                  </TableCell>
                  <TableCell className={classesList.TableCell}>
                    Revenue Account
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell className={classesList.TableCell}>
                    Expense Account
                  </TableCell>
                  <TableCell className={classesList.TableCell}>
                    Liability Account
                  </TableCell>
                </>
              )}
              {/* <TableCell className={classesList.TableCell}>Receipt / Expense / Revenue</TableCell> */}
              <TableCell className={classesList.TableCell}>Tax</TableCell>
              <TableCell className={classesList.TableCell}>Quantity</TableCell>
              <TableCell className={classesList.TableCell}>
                Unit Cost{' '}
              </TableCell>
              <TableCell className={classesList.TableCell}>
                Discount (%)
              </TableCell>
              <TableCell className={classesList.TableCell}>
                Sub Total{' '}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ paddingBottom: 5 }}>
            {props.items
              ? props.items.map((item, index) => {
                  return (
                    <>
                      <TableRow key={item.id}>
                        <TableCell className={classesList.TableID}>
                          {index + 1}
                        </TableCell>
                        <TableCell>{item.itemName}</TableCell>
                        {isAr ? (
                          <>
                            <TableCell className={classesList.TableCell}>
                              {item?.assetAcct?.code}-{item?.assetAcct?.name}
                            </TableCell>
                            <TableCell className={classesList.TableCell}>
                              {item?.revenueAcct?.code}-
                              {item?.revenueAcct?.name}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell className={classesList.TableCell}>
                              {item?.expenseAcct?.code}-
                              {item?.expenseAcct?.name}
                            </TableCell>
                            <TableCell className={classesList.TableCell}>
                              {item?.libailityAcct?.code}-
                              {item?.libailityAcct?.name}
                            </TableCell>
                          </>
                        )}
                        {/* <TableCell className={classesList.TableCell}>{item?.receiptNumber[0] || !item?.expenseType?.includes('undefined') ? item.expenseType : ""}</TableCell> */}
                        <TableCell className={classesList.TableCell}>
                          {item?.taxAmt}%
                        </TableCell>
                        <TableCell className={classesList.TableCell}>
                          {addZeroes(item.quantity)}
                        </TableCell>
                        <TableCell className={classesList.TableCell}>
                          {props.currency?.fc?.Code} {addZeroes(item.unitCost)}{' '}
                          <br />
                          {props.currency?.fc?._id !== props.currency?.bc?._id
                            ? `(${props.currency?.bc?.Code} ${addZeroes(
                                item?.unitCost_bc
                              )})`
                            : ''}{' '}
                        </TableCell>
                        <TableCell className={classesList.TableCell}>
                          {props.currency?.fc?.Code}{' '}
                          {parseFloat(
                            addZeroes(
                              ((item.discount * item.unitCost) / 100) *
                                item.quantity
                            )
                          ).toFixed(2)}
                          ({item.discount}%)
                          <br />
                          {props.currency?.fc?._id !== props.currency?.bc?._id
                            ? `(${props.currency?.bc?.Code} ${parseFloat(
                                addZeroes(
                                  ((item.discount * item.unitCost_bc) / 100) *
                                    item.quantity
                                )
                              ).toFixed(2)})`
                            : ''}{' '}
                        </TableCell>
                        <TableCell className={classesList.TableCell}>
                          {props.currency?.fc?.Code} {addZeroes(item.amount)}{' '}
                          <br />
                          {props.currency?.fc?._id !== props.currency?.bc?._id
                            ? `(${props.currency?.bc?.Code} ${addZeroes(
                                item.amount_bc
                              )})`
                            : ''}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={9}>
                          <Typography variant='body1' gutterBottom>
                            File Description :
                          </Typography>
                          {item?.additionalDetails}
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })
              : ''}
          </TableBody>
        </Table>
      </GridContainer>
    </Animated>
  )
}
