import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItem,
  TextField,
  Switch,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Backdrop,
  Typography
} from '@material-ui/core'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import ChipInput from 'material-ui-chip-input'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
// @material-ui/icon
import VisibilityIcon from '@material-ui/icons/Visibility'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import Swal from 'sweetalert2'
import {
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import { setIsTokenExpired } from 'actions'
import TimezoneSelect from 'react-timezone-select'

const styles = (theme) => ({
  root: {
    // textAlign: "center",
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  statusImage: {
    width: 50,
    height: 50
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const useStyles = makeStyles(styles)
const sweetAlertStyle = makeStyles(styles2)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export default function SyncConfigrations() {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const userDetails = jwt.decode(Token)
  const dispatch = useDispatch()
  const classes = useStyles()
  const [isLoading, setIsLoading] = React.useState(false)
  const [mainLoading, setMainLoading] = React.useState(false)
  const [organizationFilter, setOrganizationFilter] = React.useState('')
  const [organizations, setOrganizations] = React.useState([])
  const [data, setData] = React.useState()
  const [animateTableView, setAnimateTableView] = React.useState(true)
  const [animateTable, setAnimateTable] = React.useState(true)
  const [selectedTimezone, setSelectedTimezone] = React.useState('')
  const [state, setState] = React.useState({
    values: {
      syncTime: 1,
      autoExportEntry: false,
      exportEntry: 'Draft'
    }
  })

  React.useEffect(() => {
    setMainLoading(true)
    let userDetail = jwt.decode(Token)
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getAccountsConfig`,
      headers: { cooljwt: Token }
    })
      .then((response) => {
        setMainLoading(false)
        let config = response.data
        setState((state) => ({
          ...state,
          values: {
            ...state.values,
            syncTime: config.syncTime || '',
            autoExportEntry: config.autoExportEntry || false,
            exportEntry: config.exportEntry || ''
          }
        }))
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const saveConfigration = () => {
    setIsLoading(true)
    let body = {
      syncTime: parseInt(state.values.syncTime),
      autoExportEntry: state.values.autoExportEntry,
      exportEntry: state.values.exportEntry
    }
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/saveAccountsConfig`,
      data: body,
      headers: { cooljwt: Token }
    })
      .then((response) => {
        setIsLoading(false)
        successAlert('Configration Updated')
        console.log(response)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleChange = (event) => {
    event.persist()
    if (event.target.name == 'syncTime' || event.target.name == 'exportEntry') {
      setState((state) => ({
        ...state,
        values: {
          ...state.values,
          [event.target.name]: event.target.value
        }
      }))
    } else {
      setState((state) => ({
        ...state,
        values: {
          ...state.values,
          [event.target.name]: event.target.checked
        }
      }))
    }
  }
  return (
    <div>
      {animateTableView ? (
        !mainLoading ? (
          <>
            <Animated
              animationIn='bounceInRight'
              animationOut='bounceOutLeft'
              animationInDuration={1000}
              animationOutDuration={1000}
              isVisible={animateTable}
            >
              <GridContainer>
                <GridItem xs={12}>
                  <Card>
                    <CardHeader color='info' icon>
                      <CardIcon color='info'>
                        <h4 className={classes.cardTitleText}>
                          Accounts Configurations
                        </h4>
                      </CardIcon>
                    </CardHeader>
                    <CardBody>
                      <List>
                        <ListItem>
                          <ListItemText
                            style={{ color: 'black' }}
                            primary='Auto Export Entires'
                            secondary={'System will auto export entires'}
                          />
                          <ListItemSecondaryAction>
                            <Checkbox
                              color='primary'
                              value={state.values.autoExportEntry}
                              checked={state.values.autoExportEntry}
                              name='autoExportEntry'
                              onChange={handleChange}
                            />
                          </ListItemSecondaryAction>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <TextField
                            fullWidth={true}
                            label='Set Duration (System will export entries after specified hours)'
                            id='syncTime'
                            name='syncTime'
                            variant='standard'
                            select
                            onChange={(event) => {
                              handleChange(event)
                            }}
                            value={state.values.syncTime || ''}
                          >
                            <MenuItem selected disabled value=''>
                              Select Time{' '}
                            </MenuItem>
                            <MenuItem selected value='1'>
                              1 Hour
                            </MenuItem>
                            <MenuItem selected value='2'>
                              {' '}
                              2 Hours
                            </MenuItem>
                            <MenuItem selected value='3'>
                              {' '}
                              3 Hours
                            </MenuItem>
                            <MenuItem selected value='4'>
                              4 Hours
                            </MenuItem>
                            <MenuItem selected value='5'>
                              5 Hours
                            </MenuItem>
                            <MenuItem selected value='6'>
                              6 Hours
                            </MenuItem>
                            <MenuItem selected value='7'>
                              {' '}
                              7 Hours
                            </MenuItem>
                            <MenuItem selected value='8'>
                              {' '}
                              8 Hours
                            </MenuItem>
                            <MenuItem selected value='9'>
                              9 Hours
                            </MenuItem>
                            <MenuItem selected value='10'>
                              10 Hours
                            </MenuItem>
                            <MenuItem selected value='11'>
                              11 Hours
                            </MenuItem>
                            <MenuItem selected value='12'>
                              12 Hours
                            </MenuItem>
                            <MenuItem selected value='24'>
                              24 Hours
                            </MenuItem>
                          </TextField>
                        </ListItem>
                        <Divider />
                        <ListItem>
                          <TextField
                            fullWidth={true}
                            label='Export Entries As'
                            id='exportEntry'
                            name='exportEntry'
                            variant='standard'
                            select
                            onChange={(event) => {
                              handleChange(event)
                            }}
                            value={state.values.exportEntry || ''}
                          >
                            <MenuItem selected disabled value=''>
                              Select
                            </MenuItem>
                            <MenuItem value='Draft'>Draft</MenuItem>
                            <MenuItem value='Posted'>Posted</MenuItem>
                          </TextField>
                        </ListItem>
                      </List>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
              <Button
                color='info'
                className={classes.registerButton}
                style={{ float: 'right', marginTop: 20 }}
                round
                type='button'
                onClick={saveConfigration}
              >
                {isLoading ? (
                  <CircularProgress style={{ color: 'white' }} />
                ) : (
                  'Save Configration'
                )}
              </Button>
            </Animated>
          </>
        ) : (
          <Backdrop className={classes.backdrop} open={mainLoading}>
            <CircularProgress color='inherit' />
          </Backdrop>
        )
      ) : (
        ''
      )}
    </div>
  )
}
