import React from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components

import Footer from 'components/Footer/Footer.js'

import styles from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.js'

import login from 'assets/img/login.jpeg'

import HomeScreenNavbar from 'components/Navbars/HomeScreenNavbar'
import Home from 'views/LDocs/HomePage/Home'

const useStyles = makeStyles(styles)

export default function HomeLayout(props) {
  const { ...rest } = props
  // ref for the wrapper div
  const wrapper = React.createRef()

  // ** hooks
  const location = useLocation()
  const currentPath = location.pathname

  // styles
  const classes = useStyles()
  React.useEffect(() => {
    document.body.style.overflow = 'unset'
    // Specify how to clean up after this effect:
    return function cleanup() {}
  }, [])

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views)
      }
      if (prop.layout === '/auth') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  }
  const getBgImage = () => {
    return login
  }
  const getActiveRoute = (routes) => {
    console.log(routes)
    let activeRoute = 'Default Brand Text'
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views)
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name
        }
      }
    }
    return activeRoute
  }
  return (
    <div>
      <HomeScreenNavbar />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: 'url(' + getBgImage() + ')' }}
        >
          <Home />
          <Footer white />
        </div>
      </div>
    </div>
  )
}
