import React from 'react';
import ReactTable from 'react-table';
// @material-ui/core components
import {
    makeStyles,
    CircularProgress,
    LinearProgress,
    Slide,
    Dialog,
    DialogContent,
    Tooltip,
    IconButton,
    Badge,
    withStyles,
    Typography,
    SwipeableDrawer,
    Chip
} from '@material-ui/core'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import RefreshIcon from '@material-ui/icons/Refresh'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import {
    addZeroes,
    currentTracking,
    formatDate,
    formatDateTime
} from 'views/LDocs/Functions/Functions'
import 'react-contexify/dist/ReactContexify.min.css'
import Swal from 'sweetalert2'
import {
    successAlert,
    errorAlert,
    msgAlert
} from 'views/LDocs/Functions/Functions';

const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: '15px',
        marginBottom: '0px'
    },
    cardTitleText: {
        color: 'white'
    }
}

const useStyles = makeStyles(styles);

const Receipts = () => {

    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(false);
    const [data, setData] = React.useState([]);

    return (
        <div>
            <Animated
                animationIn='bounceInRight'
                animationOut='bounceOutLeft'
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
            >
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color='info' icon>
                                <CardIcon color='info'>
                                    <h4 className={classes.cardTitleText}>Receipts</h4>
                                </CardIcon>
                                {/* <Button
                                    color='danger'
                                    round
                                    style={{ float: 'right' }}
                                    className={classes.marginRight}
                                    // onClick={() => setShowFiltersModel(true)}
                                >
                                    Add PO
                                </Button> */}
                            </CardHeader>
                            <CardBody>
                                {isLoading ? (
                                    <LinearProgress />
                                ) : (
                                    <ReactTable
                                        data={data}
                                        sortable={false}
                                        columns={[
                                            {
                                                Header: 'Date of Issue  ',
                                                accessor: 'invoiceId',
                                                // filterable: true,
                                                // filter: 'fuzzyText',
                                                // sortType: 'basic'
                                            },
                                            {
                                                Header: 'Date of Expiry',
                                                accessor: 'status'
                                            },
                                            {
                                                Header: 'PO Amount',
                                                accessor: 'createdDate'
                                            },
                                            {
                                                Header: 'Payment Terms',
                                                accessor: 'customerName'
                                            },
                                            {
                                                Header: 'Partial Delivery',
                                                accessor: 'netAmt'
                                            },
                                        ]
                                        }
                                        defaultPageSize={10}
                                        showPaginationTop
                                        showPaginationBottom={true}
                                        className='-striped -highlight'
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </Animated>

        </div>
    )
}

export default Receipts