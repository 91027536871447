import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Popper,
  Paper,
  Grow,
  MenuList,
  Avatar
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect } from 'react'
import { useHistory, Link as RouterLink } from 'react-router-dom'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

// ** icons
import companyLogo from '../../assets/img/icons/companyLogo.png'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'

// ** Logout Request
import jwt from 'jsonwebtoken'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import DeviceUUID, { _IsAr } from '../../views/LDocs/Functions/Functions'
import { setToken } from 'actions'
import { logoutUserAction, getNotification, getTasks } from 'actions'
import { NavbarNotifications } from './AdminNavbarLinks'

const headersData = [
  {
    label: 'My Profile',
    href: '/account'
  },
  {
    label: 'Log Out',
    href: '/logout'
  }
]

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: 'aqua',
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    paddingRight: '25px',
    paddingLeft: '55px',
    paddingTop: '2px',
    paddingBottom: '2px',
    '@media (max-width: 900px)': {
      paddingLeft: 0
    }
  },
  logo: {
    fontFamily: 'Work Sans, sans-serif',
    fontWeight: 600,
    color: '#FFFEFE',
    textAlign: 'left'
  },
  menuButton: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 700,
    size: '18px',
    marginLeft: '38px'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  drawerContainer: {
    padding: '20px 30px'
  },
  root: {
    display: 'flex'
  },
  paper: {
    marginRight: theme.spacing(2)
  },
  myButton: {
    cursor: 'pointer'
  }
}))

let uuid = new DeviceUUID().get()
export default function HomeScreenNavbar() {
  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles()
  const classes = useStyles()

  // ** history
  const history = useHistory()

  // ** states
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  })
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const { mobileView, drawerOpen } = state
  const [logoutCheck, setLogoutCheck] = useState(false)

  // ** redux & store
  const dispatch = useDispatch()
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const userData = jwt.decode(Token)

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())

    return () => {
      window.removeEventListener('resize', () => setResponsiveness())
    }
  }, [])

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {femmecubatorLogo}
        <div>{getMenuButtons()}</div>
      </Toolbar>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: 'start',
            color: 'inherit',
            'aria-label': 'menu',
            'aria-haspopup': 'true',
            onClick: handleDrawerOpen
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: 'left',
            open: drawerOpen,
            onClose: handleDrawerClose
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: 'inherit',
            style: { textDecoration: 'none' },
            key: label
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      )
    })
  }

  const femmecubatorLogo = (
    <Typography variant='h6' component='h1' className={logo}>
      <img src={companyLogo} height={40} alt='avatarImg' />
    </Typography>
  )

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return
    // }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  // ** notification component call
  const [openNotification, setOpenNotification] = useState(null)
  const notifications = useSelector((state) => state.userReducer.notifications)

  function fetchData() {
    dispatch(getNotification())
    dispatch(getTasks())
  }

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null)
    } else {
      setOpenNotification(event.currentTarget)
    }
  }
  const handleCloseNotification = () => {
    setOpenNotification(null)
  }
  const handleMarkNotificationRead = (notification_id, status) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/notify/updateSysNotify`,
      data: {
        _id: notification_id,
        status: status
      },
      headers: { 'Content-Type': 'application/json', cooljwt: Token }
    }).then((response) => {
      fetchData()
    })
  }

  const getMenuButtons = () => {
    return (
      <>
        <div className={classes.root}>
          <div>
            <Button
              {...{
                color: 'inherit',
                marginLeft: 0,
                className: menuButton
              }}
            >
              {/* <ChatOutlinedIcon color='white' /> */}
            </Button>
            {/* <Button
              {...{
                color: 'inherit',
                marginLeft: 0,
                className: menuButton
              }}
              style={{ marginLeft: 0 }}
            >
              <NotificationsOutlinedIcon color='white' />
            </Button> */}
            <NavbarNotifications
              handleClickNotification={handleClickNotification}
              handleCloseNotification={handleCloseNotification}
              handleMarkNotificationRead={handleMarkNotificationRead}
              openNotification={openNotification}
              setOpenNotification={setOpenNotification}
              notifications={notifications}
              classes={classes}
              screen='home'
            />
            <Button
              {...{ color: 'inherit' }}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
            >
              <PersonOutlineIcon color='white' />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id='menu-list-grow'
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem
                          onClick={() => {
                            handleClose()
                            history.push('/default/user-profile')
                          }}
                        >
                          My Profile
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose()
                            handleLogoutUser()
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </>
    )
  }

  const handleLogoutUser = () => {
    const usr = jwt.decode(Token)
    axios({
      method: 'delete', //you can set what request you want to be
      url: usr.isVendor
        ? `${process.env.REACT_APP_LDOCS_API_URL}/vendor/deleteFcm`
        : `${process.env.REACT_APP_LDOCS_API_URL}/user/deleteUserFcm`,
      data: {
        deviceId: uuid
      },
      headers: {
        cooljwt: Token
      }
    })
      .then((res) => {
        console.log('logout')
        localStorage.clear()
        dispatch(setToken(null))
        dispatch(logoutUserAction())
        setLogoutCheck(true)
      })
      .catch((err) => {
        localStorage.clear()
        dispatch(setToken(null))
        dispatch(logoutUserAction())
        console.log(err)
      })
  }

  return (
    <header>
      <AppBar className={classes.header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  )
}
