import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'
import Iframe from 'react-iframe'
import { useReactToPrint } from 'react-to-print'
import { Link } from 'react-router-dom'
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak'
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong'
import DateRangeIcon from '@material-ui/icons/DateRange'
import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'
// @material-ui/core components
import {
  makeStyles,
  CircularProgress,
  LinearProgress,
  Slide,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  Badge,
  withStyles,
  Typography,
  SwipeableDrawer,
  Chip,
  Checkbox,
  Avatar,
  Divider,
  MenuItem,
  TextField,
  TablePagination
} from '@material-ui/core'
// @material-ui/icons
import {
  LocalOffer,
  Visibility,
  Send,
  Edit,
  EditOutlined,
  Done,
  Info
} from '@material-ui/icons'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardFooter from 'components/Card/CardFooter.js'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import CardIcon from 'components/Card/CardIcon.js'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'
import ClearAllIcon from '@material-ui/icons/ClearAll'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import dateFormat from 'dateformat'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import FileAdvanceView from '../Invoices/AdvanceView/FileAdvanceView'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import FiberNewIcon from '@material-ui/icons/FiberNew'
import RateReview from '@material-ui/icons/RateReview'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import {
  addZeroes,
  formatDate,
  formatDateTime,
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions'
import {
  Menu,
  Item,
  Separator,
  animation,
  MenuProvider,
  theme,
  Submenu
} from 'react-contexify'
import 'react-contexify/dist/ReactContexify.min.css'
import Swal from 'sweetalert2'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import Pending from 'assets/img/statuses/Pending.png'
import Success from 'assets/img/statuses/Success.png'
import Rejected from 'assets/img/statuses/Rejected.png'
import NoStatus from 'assets/img/statuses/NoStatus.png'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import { useDispatch, useSelector } from 'react-redux'
import ExportToFusion from './ExportToFusion'
import Filters from './Filters'
import { over } from 'lodash'
import { setIsTokenExpired } from 'actions'
import moment from 'moment'
import { htmlAlert } from '../Functions/Functions'

const useStyles = makeStyles(styles)
const sweetAlertStyle = makeStyles(styles2)
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -7,
    top: -2,
    border: `2px solid #9E2654`,
    padding: '0 4px',
    background: '#9E2654'
  }
}))(Badge)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})
const TransitionRight = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='right' ref={ref} {...props} />
})
const TransitionLeft = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='left' ref={ref} {...props} />
})

export default function ExportList(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const isAr = useSelector((state) => state.userReducer.isAr)
  const dispatch = useDispatch()
  let userDetail = jwt.decode(localStorage.getItem('im-mate'))
  let isVendor = userDetail.isVendor
  const classes = useStyles()
  const [componentName, setComponentName] = React.useState('Export to GL')
  const [updateDateModal, setUpdateDateModal] = React.useState(false)
  const [animateTable, setAnimateTable] = React.useState(true)
  const [animatePdf, setAnimatePdf] = React.useState(false)
  const [animateQr, setAnimateQr] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [exported, setExported] = React.useState(false)
  const [isViewing, setIsViewing] = React.useState(false)
  const [selected, setSelected] = React.useState([])
  const [pdfUrl, setPdfUrl] = React.useState(false)
  const [pdfModalData, setPdfModalData] = React.useState(false)
  const [data, setData] = React.useState()
  const [row, setRow] = React.useState()
  const [rowData, setRowData] = React.useState(null)
  const [editIndex, setEditIndex] = React.useState(null)
  const [openDates, setOpenDates] = React.useState([])
  const [exportToFusionModel, setExportToFusionModel] = React.useState(false)
  const [filesData, setFilesData] = React.useState([])
  const [formState, setFormState] = React.useState({
    files: [],
    totalInvoices: 0,
    paymentDue: 0,
    overDue: 0,
    paymentInProcess: 0,
    vendors: [],
    export: null,
    pos: [],
    filter: null,
    exported: false,
    selectedPeriod: ''
  })
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [totalCount, setTotalCount] = React.useState(0)

  //Use Effect Hook
  React.useEffect(() => {
    getOpenPeriods()
  }, [])

  //Use Effect Hook
  React.useEffect(() => {
    getMyFiles(userDetail, true)
  }, [page, rowsPerPage])

  const sumOfEntries = (lines) => {
    let Total
    Total = lines.reduce(function(prev, current) {
      return prev + current.debitAmount
    }, 0)
    return Total
  }
  const updateJournalDate = (prop, index) => {
    setRowData(prop)
    setEditIndex(index)
    setUpdateDateModal(true)
  }

  const setTableData = (response) => {
    setData(
      response.map((prop, index) => {
        let isSelected =
          selected.filter((s) => s.id == prop._id).length > 0 ? true : false
        return {
          id: prop._id,
          invoiceId: prop.voucherRef,
          narrtion: prop.voucherRef,
          createdDate: (
            <MenuProvider data={prop} id='menu_id'>
              {formatDateTime(prop.createdAt)}
            </MenuProvider>
          ),
          journalDate: (
            <MenuProvider data={prop} id='menu_id'>
              {formatDate(prop.journalDate)}
            </MenuProvider>
          ),
          requester: (
            <MenuProvider data={prop} id='menu_id'>
              {prop.createdByName}
            </MenuProvider>
          ),
          netAmt: (
            <MenuProvider data={prop} id='menu_id'>
              {addZeroes(sumOfEntries(prop?.addDebitLines))}
            </MenuProvider>
          ),
          select: (
            <div className='actions'>
              <Checkbox
                // disabled={
                //   formState.filter == "totalInvCount" ||
                //   formState.filter == "paymentInProcessCount"
                // }
                checked={isSelected}
                onChange={() => select(prop, 'single')}
              />
            </div>
          ),
          actions: (
            <div className='actions-right'>
              <Tooltip title='Change journal date' aria-label='viewfile'>
                <Button
                  justIcon
                  round
                  simple
                  icon={DateRangeIcon}
                  onClick={() => {
                    updateJournalDate(prop, index)
                  }}
                  color='danger'
                >
                  <DateRangeIcon />
                </Button>
              </Tooltip>
              {prop?.errorMessage ? (
                <Tooltip title={prop?.errorMessage} aria-label='viewfile'>
                  {/* <Button
                    justIcon
                    round
                    simple
                    disabled
                    icon={Info}
                    color="danger"
                  > */}
                  <IconButton color='danger' size='small'>
                    <Info fontSize='small' />
                  </IconButton>
                  {/* </Button> */}
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          )
        }
      })
    )
  }

  //Get Open Periods
  const getOpenPeriods = async () => {
    axios({
      method: 'get', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/accounts/checkDate`,
      headers: {
        cooljwt: Token
      }
    })
      .then((response) => {
        setOpenDates(response.data)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
      })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  //Get Files
  const getMyFiles = async () => {
    setIsLoading(true)
    axios({
      method: 'post', //you can set what request you want to be
      url: `${process.env.REACT_APP_LDOCS_API_URL}/invoiceApprove/getTransactions?pageNumber=${page}&pageSize=${rowsPerPage}&statusType=Draft`,
      headers: {
        cooljwt: Token
      }
    })
      .then((response) => {
        setFilesData(response.data)
        setTableData(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
        setIsLoading(false)
      })
  }

  const select = (invoice, type) => {
    let selectedInvoices = selected
    if (exported) {
      selectedInvoices = []
      setExported(false)
    }
    if (!invoice) {
      if (selected.length == filesData.length) {
        selectedInvoices = []
      } else {
        filesData.map((file) => {
          if (
            selected.filter(
              (s) =>
                s.id == file.invoiceId &&
                s.version == file.version &&
                s.vendorId == file.vendorId
            ).length < 1
          ) {
            selectedInvoices.push({
              id: file.invoiceId,
              version: file.version,
              vendorId: file.vendorId
            })
          }
        })
      }
    } else {
      if (type === 'single') {
        if (selected.filter((s) => s.id == invoice._id).length < 1) {
          selectedInvoices.push({
            ...invoice,
            id: invoice._id
          })
        } else {
          const index = selected.findIndex((s) => s.id == invoice._id)
          if (index > -1) {
            selectedInvoices.splice(index, 1)
          }
        }
      } else {
        filesData.map((file) => {
          if (selected.filter((s) => s.id == file._id).length < 1) {
            selectedInvoices.push({
              ...file,
              id: file._id
            })
          } else {
            const index = selected.findIndex((s) => s.id == file._id)
            if (index > -1) {
              selectedInvoices.splice(index, 1)
            }
          }
        })
      }
    }
    setSelected(selectedInvoices)
    setTableData(filesData)
  }

  React.useEffect(() => {
    setTableData(filesData)
  }, [selected.length])

  const exportTransactions = (n) => {
    // n = 1 && export Invoices
    // n = 2 && Payment Invoices
    setFormState((formState) => ({
      ...formState,
      export: n
    }))
    let userDetail = jwt.decode(localStorage.getItem('im-mate'))
    setExportToFusionModel(true)
    setExported(true)
    setSelected([])
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/accounts/exportInvoice`,
      data: { list: selected },
      headers: {
        cooljwt: Token
      }
    })
      .then(async (response) => {
        setFormState((formState) => ({
          ...formState,
          filter: null
        }))
        let userDetail = jwt.decode(localStorage.getItem('im-mate'))
        await getMyFiles(userDetail, false)
        setExportToFusionModel(false)
        successAlert('Transaction Exported Successfully')
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        if (error?.response?.data?.errorArr) {
          let errorr = error?.response?.data?.errorArr
          let errorString =
            '<h5> Errors in Export </h5> <ul style="text-align:left">'

          {
            errorr.map((err) => {
              errorString += `<li>${err}</li>`
            })
          }
          errorString += '</ul>'

          htmlAlert({ html: errorString })
        }
        setExportToFusionModel(false)
        setExported(false)
        setSelected([])
      })
  }

  const handleChange = (value) => {
    let date = value?.slice(0, 10)
    setFormState((formState) => ({
      ...formState,
      selectedPeriod: date
    }))
  }

  const updateDate = () => {
    let orgArray = filesData
    orgArray[editIndex].journalDate = formState.selectedPeriod
    setTableData(orgArray)
    setRowData(null)
    setEditIndex(null)
    setUpdateDateModal(false)
  }

  return (
    <div>
      {/* Mark As Payment Model */}
      {exportToFusionModel ? (
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + ' ' + classes.modalRoot,
                paper: classes.modal
              }}
              fullWidth={true}
              maxWidth={'sm'}
              scroll='body'
              open={exportToFusionModel}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setExportToFusionModel(false)}
              aria-labelledby='tag-modal-slide-title'
              aria-describedby='tag-modal-slide-description'
            >
              <DialogContent
                id='tag-modal-slide-description'
                className={classes.modalBody}
              >
                <ExportToFusion
                  closeModal={() => setExportToFusionModel(false)}
                  fileData={row}
                  export={formState.export || 1}
                  loadFiles={getMyFiles}
                />
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ''
      )}

      {/* Mark As Payment Model */}
      {updateDateModal ? (
        <GridContainer justify='center'>
          <GridItem xs={12} sm={12} md={12} className={classes.center}>
            <Dialog
              classes={{
                root: classes.center + ' ' + classes.modalRoot,
                paper: classes.modal
              }}
              fullWidth={true}
              maxWidth={'sm'}
              scroll='body'
              open={updateDateModal}
              TransitionComponent={Transition}
              keepMounted
              onClose={() => setUpdateDateModal(false)}
              aria-labelledby='tag-modal-slide-title'
              aria-describedby='tag-modal-slide-description'
            >
              <DialogContent
                id='tag-modal-slide-description'
                className={classes.modalBody}
              >
                <Card>
                  <CardHeader color='danger' icon>
                    <CardIcon color='danger' style={{ padding: '4px' }}>
                      <h4 className={classes.cardTitleText}>
                        Update Journal Date
                      </h4>
                    </CardIcon>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <TextField
                          className={classes.textField}
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'black' },
                            classes: {
                              asterisk: classes.asterisk
                            }
                          }}
                          disabled
                          size='small'
                          label='Current Date'
                          name='period'
                          type='text'
                          // onChange={(event) => {

                          // }}
                          value={moment(rowData.journalDate).format(
                            'YYYY-MM-DD'
                          )}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <TextField
                          className={classes.textField}
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true,
                            style: { color: 'black' },
                            classes: {
                              asterisk: classes.asterisk
                            }
                          }}
                          size='small'
                          select
                          label='Select Period'
                          name='period'
                          type='text'
                          onChange={(event) => {
                            handleChange(event.target.value)
                          }}
                          // value={formState.selectedPeriod || ''}
                        >
                          <MenuItem disabled>Choose Period</MenuItem>
                          {openDates.map((period, index) => (
                            <MenuItem key={index} value={period.monthUTCDate}>
                              {period?.monthsName}
                            </MenuItem>
                          )) || []}
                        </TextField>
                      </GridItem>
                      <GridItem
                        style={{
                          display: 'flex',
                          alignItems: 'right',
                          justifyContent: 'flex-end',
                          marginTop: '20px'
                        }}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <Button
                          color='danger'
                          size='small'
                          onClick={() => {
                            setRowData(null)
                            setEditIndex(null)
                            setUpdateDateModal(false)
                          }}
                          round
                        >
                          Close
                        </Button>
                        <Button
                          color='info'
                          size='small'
                          onClick={() => updateDate()}
                          round
                        >
                          Update
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </DialogContent>
            </Dialog>
          </GridItem>
        </GridContainer>
      ) : (
        ''
      )}

      {animateTable ? (
        <Animated
          animationIn='bounceInRight'
          animationOut='bounceOutLeft'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateTable}
        >
          <GridContainer>
            <GridItem xs={12}>
              <Card>
                <CardHeader color='danger' icon>
                  <CardIcon color='danger'>
                    <h4 className={classes.cardTitleText}>{componentName}</h4>
                  </CardIcon>
                  <Button
                    color='danger'
                    round
                    style={{ float: 'right' }}
                    className={classes.marginRight}
                    onClick={() =>
                      selected.length > 0
                        ? exportTransactions(1)
                        : msgAlert('Please Select Transaction.')
                    }
                  >
                    Ready To Export ({selected.length})
                  </Button>
                </CardHeader>
                <CardBody>
                  {isLoading ? (
                    <LinearProgress />
                  ) : (
                    <>
                      <ReactTable
                        data={data}
                        sortable={false}
                        columns={[
                          {
                            Header: (
                              <Checkbox
                                checked={
                                  filesData.length == selected.length &&
                                  selected.length >= 0
                                }
                                onChange={() => select(filesData, 'all')}
                              />
                            ),
                            accessor: 'select',
                            disableSortBy: false,
                            width: 100
                          },
                          {
                            Header: 'Invoice ID',
                            accessor: 'invoiceId',
                            filterable: true,
                            filter: 'fuzzyText'
                          },
                          {
                            Header: 'Narration',
                            accessor: 'narrtion'
                          },
                          {
                            Header: 'Created Date',
                            accessor: 'createdDate'
                          },
                          {
                            Header: 'Jornal Date',
                            accessor: 'journalDate'
                          },
                          {
                            Header: 'Requester',
                            accessor: 'requester'
                          },
                          {
                            Header: 'Amount',
                            accessor: 'netAmt',
                            width: 100
                          },
                          {
                            Header: 'Actions',
                            accessor: 'actions',
                            width: 100
                          }
                        ]}
                        defaultPageSize={10}
                        showPaginationTop
                        showPaginationBottom={true}
                        className='-striped -highlight'
                      />
                      {/* <TablePagination
                        component='div'
                        count={totalCount}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      /> */}
                    </>
                  )}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ''
      )}
    </div>
  )
}
