import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress, Divider, TextField } from "@material-ui/core";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  cardTitleText: {
    color: "white",
  },
  buttonRight: {},
};

const useStyles = makeStyles(styles);

export default function AccountPayable({ accountList, state, setState, saveCOADefault, isLoading }) {
  const classes = useStyles();

  const handleChange = (name, value) => {
    let data = {
      id: value._id,
      name: value.name,
      code: value.code
    }
    setState((state) => ({
      ...state,
      [name]: data,
    }));
  }


  return (
    <>
      <div>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <h4 className={classes.cardCategory} style={{ margin: 0 }}>
                  Pre-Payment / Advance Payments
                </h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Assets') || []}
                    size='small'
                    value={state.prePayAsset || ''}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('prePayAsset', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Asset Account'
                        variant='standard'
                        // error={state.prePayAsset?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.prePayAsset}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <Divider />
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Revenue') || []}
                    size='small'
                    value={state.prePayRev || ''}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('prePayRev', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Revenue Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.prePayRev || ''}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <br />
        <GridItem xs={12}>
          <Card>
            <CardHeader color="danger" icon>
              <CardIcon color="danger">
                <h4 className={classes.cardCategory} style={{ margin: 0 }}>
                  With Receipt Invoices
                </h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Assets') || []}
                    size='small'
                    value={state.receiptAsset}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('receiptAsset', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Asset Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.receiptAsset}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <Divider />
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Revenue') || []}
                    size='small'
                    value={state.receiptRev}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('receiptRev', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Revenue Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.receiptRev}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <br />

        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <h4 className={classes.cardCategory} style={{ margin: 0 }}>
                  Standard / Petty Cash Invoices
                </h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              {" "}
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Assets') || []}
                    size='small'
                    value={state.pettyCashAsset}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('pettyCashAsset', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Asset Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.pettyCashAsset}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
                <Divider />
                <GridItem xs={6}>
                  <Autocomplete
                    id='parentAccount_auto_complete'
                    options={accountList.filter((x) => x.accountParentName === 'Revenue') || []}
                    size='small'
                    value={state.pettyCashRev}
                    getOptionLabel={option => option !== '' &&
                      ` ${option?.code} - ${option?.name}` || ''
                    }
                    // loading={isLoading}
                    onChange={(event, newValue) => {
                      handleChange('pettyCashRev', newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name='coaId'
                        label='Revenue Account'
                        variant='standard'
                        // error={row?.errorCoa === 'error'}
                        InputLabelProps={{
                          shrink: true,
                          style: { color: 'black' },
                          classes: {
                            asterisk: classes.asterisk
                          }
                        }}
                        required
                        value={state.pettyCashRev}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <Button
          color="danger"
          className={classes.registerButton}
          style={{ float: "right", marginTop: 20 }}
          round
          type="button"
          onClick={() => saveCOADefault("receivable")}

        >
          {isLoading ? <CircularProgress /> : "Update"}
        </Button>
      </div>
    </>
  );
}
