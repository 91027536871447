import React, { useMemo } from 'react'
import ReactTable from 'react-table'
import { formatDateTime } from 'views/LDocs/Functions/Functions'

const index = (props) => {
  const { data } = props

  /*eslint-disable*/
  const tableData = useMemo(() => {
    let obj = []
    Object.keys(data).forEach((key) => {
      key === 'current_status' ? '' : obj.push({ review: key, ...data[key] })
    })
    return obj
  }, [data])

  return (
    <ReactTable
      data={tableData}
      sortable={false}
      defaultPageSize={10}
      minRows={5}
      columns={[
        {
          Header: 'Review / Approve',
          accessor: 'review',
          Cell: (props) => (
            <span style={{ textTransform: 'uppercase' }}>
              {props?.original?.review ? props?.original?.review : '-'}
            </span>
          )
        },
        {
          Header: 'Assigned To',
          accessor: 'status',
          Cell: (props) => (
            <span style={{ textTransform: 'uppercase' }}>
              {props?.original?.assignTo ? props?.original?.assignTo : '-'}
            </span>
          )
        },
        {
          Header: 'Assigned Date',
          accessor: 'date',
          Cell: (props) => (
            <span>
              {props?.original?.assignDate
                ? formatDateTime(props?.original?.assignDate)
                : '-'}
            </span>
          )
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: (props) => (
            <span style={{ textTransform: 'capitalize' }}>
              {props?.original?.status ? props?.original?.status : '-'}
            </span>
          )
        },
        {
          // Header: 'Action Date',
          Header: () => (
            <div
              style={{
                textAlign: 'left'
              }}
            >
              Action Date
            </div>
          ),
          accessor: 'date',
          Cell: (props) => (
            <span>
              {props?.original?.date
                ? formatDateTime(props?.original?.date)
                : '-'}
            </span>
          )
        }
      ]}
      showPaginationTop={false}
      showPaginationBottom={false}
    />
  )
}

export default index
