import React, { useEffect, useState } from 'react'
import Button from 'components/CustomButtons/Button.js'
// import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {
  Slide,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react'

import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import GridItem from 'components/Grid/GridItem'
import { AttachmentOutlined, AttachmentRounded } from '@material-ui/icons'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import VisibilityIcon from '@material-ui/icons/Visibility'
import GridContainer from 'components/Grid/GridContainer'
import {
  errorAlert,
  generateUniqueId,
  msgAlert,
  successAlert
} from '../Functions/Functions'
import { useDispatch, useSelector } from 'react-redux'
import { setIsTokenExpired } from 'actions'
import axios from 'axios'
import jwt from 'jsonwebtoken'
import moment from 'moment'

const styles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  table: {
    minWidth: '100%'
  },
  itemName: {
    width: 400
  },
  itemNumber: {
    width: '55%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const CreatePO = ({
  formState,
  setFormState,
  createPO,
  setCreatePO,
  handleClose,
  supplierName,
  getData,
  editSingleRecord
}) => {
  const classes = styles()

  let fileInput = React.createRef()

  const [formErrors, setFormErrors] = useState({})
  const [attachmentModal, setAttachmentModal] = useState(false)
  const [savedAttachments, setSavedAttachments] = useState([])
  const [viewFile, setViewFile] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const [pdf, setPdf] = React.useState(false)
  const [isFormSubmitLoader, setFormSubmitLoader] = React.useState(false)

  const dispatch = useDispatch()
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const isAr = useSelector((state) => state.userReducer.isAr)
  const userDetails = jwt.decode(Token)

  useEffect(() => {
    if (editSingleRecord?._id) {
      updateFormData()
    } else {
      setFormState({
        issueDate: moment(new Date()).format('YYYY-MM-DD'),
        expiryDate: '',
        poAmount: '',
        paymentTerms: '',
        partialDelivery: '',
        supplier: '',
        poNumber: ''
      })
    }
  }, [])

  const updateFormData = async () => {
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/po/getSinglePoc`,
      data: {
        organizationId: editSingleRecord.organizationId,
        poNumber: editSingleRecord.poNumber
      },
      headers: { cooljwt: Token }
    })
      .then(async (response) => {
        const data = response.data
        EditPoEntry(data[0])
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(error)
      })
  }

  const EditPoEntry = (item) => {
    setSavedAttachments(item.attachments)

    setFormState({
      issueDate: moment(item.created_at).format('YYYY-MM-DD'),
      expiryDate: moment(item.dateOfExpiry).format('YYYY-MM-DD'),
      poAmount: item.poAmount,
      paymentTerms: item.paymentTerm,
      partialDelivery: item.partialDelivery ? 'true' : 'false',
      supplier: item.vendorId,
      poNumber: item.poNumber
    })
  }

  // ** form validation
  const validateForm = () => {
    const errors = {}

    // Validate issueDate
    if (!formState.issueDate) {
      errors.issueDate = 'Issue Date is required'
    }

    // Validate expiryDate
    if (!formState.expiryDate) {
      errors.expiryDate = 'Expiry Date is required'
    }

    // Validate poAmount
    if (!formState.poAmount) {
      errors.poAmount = 'PO Amount is required'
    }

    // Validate paymentTerms
    if (!formState.paymentTerms) {
      errors.paymentTerms = 'Payment Terms is required'
    }

    // Validate partialDelivery
    if (!formState.partialDelivery) {
      errors.partialDelivery = 'Partial Delivery is required'
    }

    // Validate supplier
    if (!formState.supplier) {
      errors.supplier = 'Supplier is required'
    }

    setFormErrors(errors)

    return Object.keys(errors).length === 0 // Return true if there are no errors
  }

  const handleChange = (event) => {
    event.persist()
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }))
  }
  const handleAttachmentChange = (e) => {
    e.preventDefault()

    if (e.target.files[0].size > 6291456) {
      msgAlert('Maximum File Size 6 MB')
      fileInput.current.value = ''
    }
    if (
      e.target.files[0].type === 'image/png' ||
      e.target.files[0].type === 'image/jpeg' ||
      e.target.files[0].type === 'application/pdf'
    ) {
      let files = e.target.files

      getFileDetails(files)
    } else {
      msgAlert('File Type Not Supported')
      fileInput.current.value = ''
    }
  }

  const getFileDetails = (files) => {
    Object.entries(files).map(async ([key, value]) => {
      // if (value.type === 'image/png' || 'image/jpeg' || 'application/pdf') {
      let reader = new FileReader()
      reader.onloadend = () => {
        const fileData = {
          name: value.name,
          base64: reader.result,
          type: value.type,
          attachmentPath: '',
          file: value
        }
        setSavedAttachments([
          ...savedAttachments,
          { ...fileData, id: generateUniqueId() }
        ])
      }
      reader.readAsDataURL(value)
      fileInput.current.value = ''
    })
  }
  const viewFileHandler = (file) => {
    setFile(file)
    if (
      file?.attachmentURL?.split('.').pop() === 'pdf' ||
      file.type === 'application/pdf'
    ) {
      setPdf(true)
    } else {
      setPdf(false)
    }
    setViewFile(true)
  }
  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  const handleAttachmentDelete = (idToDelete) => {
    const filteredArray = savedAttachments?.filter(
      (item) => item.id !== idToDelete
    )
    setSavedAttachments(filteredArray)
  }

  const handleOnSubmitRequest = async () => {
    if (validateForm()) {
      let obj = {
        attachments: savedAttachments,
        poNumber: formState.poNumber,
        dateOfIssue: formState.issueDate,
        dateOfExpiry: formState.expiryDate,
        poAmount: Number(formState.poAmount),
        paymentTerm: formState.paymentTerms,
        partialDelivery: JSON.parse(formState.partialDelivery),
        currency: 'Pakistani rupee',
        vendorId: formState.supplier
      }
      setFormSubmitLoader(true)
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_LDOCS_API_URL}/po/submitPO`,
        data: editSingleRecord?._id
          ? { ...obj, ID: editSingleRecord?._id }
          : obj,
        headers: {
          cooljwt: Token
        }
      })
        .then((response) => {
          getData()
          setFormSubmitLoader(false)
          successAlert('PO Created Successfully!')
          handleClose()
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true))
          }
          console.log(
            typeof error.response != 'undefined'
              ? error.response.data
              : error.message
          )
          errorAlert(error?.response?.data)
        })
    }
  }

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        maxWidth='sm'
        open={createPO}
        keepMounted
        onClose={handleClose}
        aria-labelledby='selected-modal-slide-title'
        aria-describedby='selected-modal-slide-description'
      >
        {/* <DialogTitle id='max-width-dialog-title'>Optional sizes</DialogTitle> */}
        <DialogContent id='selected-Select' className={classes.modalBody}>
          <Card>
            <CardHeader color='info' icon>
              <CardIcon color='info'>
                <h4 className={classes.cardTitleText}>Add Purchase Order</h4>
              </CardIcon>
              <Tooltip title='Add Attachments'>
                <IconButton
                  style={{ float: 'right' }}
                  // disabled={false}
                  onClick={() => setAttachmentModal(true)}
                >
                  <AttachmentOutlined />
                </IconButton>
              </Tooltip>
            </CardHeader>
            <CardBody>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  error={!!formErrors.issueDate}
                  // error={formState.errors.reviewComments === "error"}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  // helperText={
                  //     formState.errors.reviewComments === "error"
                  //         ? "Comments is required"
                  //         : null
                  // }
                  type='date'
                  label='Issue Date'
                  name='issueDate'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.issueDate || ''}
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  // error={formState.errors.reviewComments === "error"}
                  error={!!formErrors.expiryDate}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  // helperText={
                  //     formState.errors.reviewComments === "error"
                  //         ? "Comments is required"
                  //         : null
                  // }
                  type='date'
                  label='Expiry Date'
                  name='expiryDate'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.expiryDate || ''}
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  error={!!formErrors.poNumber}
                  // error={formState.errors.reviewComments === "error"}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  // helperText={
                  //     formState.errors.reviewComments === "error"
                  //         ? "Comments is required"
                  //         : null
                  // }
                  type='text'
                  label='PO Number'
                  name='poNumber'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.poNumber || ''}
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  error={!!formErrors.poAmount}
                  // error={formState.errors.reviewComments === "error"}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  // helperText={
                  //     formState.errors.reviewComments === "error"
                  //         ? "Comments is required"
                  //         : null
                  // }
                  type='text'
                  label='PO Amount'
                  name='poAmount'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.poAmount || ''}
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  error={!!formErrors.paymentTerms}
                  // error={formState.errors.reviewComments === "error"}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  // helperText={
                  //     formState.errors.reviewComments === "error"
                  //         ? "Comments is required"
                  //         : null
                  // }
                  type='text'
                  label='Payment Terms'
                  name='paymentTerms'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.paymentTerms || ''}
                />
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  error={!!formErrors.partialDelivery}
                  // error={formState.errors.reviewComments === "error"}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  // helperText={
                  //     formState.errors.reviewComments === "error"
                  //         ? "Comments is required"
                  //         : null
                  // }
                  select
                  label='Partial Delivery'
                  name='partialDelivery'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.partialDelivery || ''}
                >
                  <MenuItem value='true'>YES</MenuItem>
                  <MenuItem value='false'>NO</MenuItem>
                </TextField>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  marginTop: '10px',
                  marginBottom: '10px'
                }}
              >
                <TextField
                  className={classes.textField}
                  error={!!formErrors.supplier}
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  select
                  label='Supplier'
                  name='supplier'
                  onChange={(event) => {
                    handleChange(event)
                  }}
                  value={formState.supplier || ''}
                >
                  {console.log(formState.supplier)}
                  {supplierName?.map((vendor, index) => {
                    return (
                      <MenuItem key={index} value={vendor?._id}>
                        {vendor?.level1.vendorName.toUpperCase()}
                      </MenuItem>
                    )
                  })}
                </TextField>
              </GridItem>
            </CardBody>
          </Card>
        </DialogContent>
        {attachmentModal ? (
          <Dialog
            classes={{
              root: classes.center + ' ' + classes.modalRoot,
              paper: classes.modal
            }}
            fullWidth={true}
            maxWidth={'md'}
            open={attachmentModal}
            keepMounted
            onClose={() => setAttachmentModal(false)}
            aria-labelledby='selected-modal-slide-title'
            aria-describedby='selected-modal-slide-description'
          >
            {/* <DialogContent id='selected-Select' className={classes.modalBody}> */}
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Attachment Details</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    lg={2}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Card
                      onClick={() => {
                        fileInput.current.click()
                      }}
                      style={{
                        padding: '10px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        background: '#dcdcdc',
                        height: '90px'
                        // margin: '5px'
                      }}
                    >
                      <AddCircleOutlineIcon
                        style={{
                          alignSelf: 'center',
                          marginBottom: 10
                        }}
                        fontSize='small'
                      />
                      <Typography variant='body1'>Add Attachment</Typography>
                    </Card>
                    <input
                      type='file'
                      name='attachments'
                      style={{ display: 'none' }}
                      accept='image/png, image/jpeg'
                      ref={fileInput}
                      onChange={(event) => {
                        handleAttachmentChange(event)
                      }}
                    />
                    {console.log(savedAttachments)}
                  </GridItem>
                  {savedAttachments?.map((res, index) => (
                    <>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        lg={2}
                        style={{
                          marginTop: '10px',
                          marginBottom: '10px',
                          marginLeft: '10px',
                          marginRight: '10px'
                        }}
                        key={res.id}
                      >
                        <Card
                          style={{
                            width: '150px',
                            height: '110px',
                            marginRight: '15px'
                          }}
                        >
                          <CardBody
                            style={{
                              textAlign: 'center',
                              cursor: 'pointer'
                            }}
                          >
                            <AttachmentRounded
                              onClick={() => viewFileHandler(res)}
                              style={{
                                alignSelf: 'center'
                              }}
                              fontSize='small'
                            />
                            <Typography variant='body1' component='h6'>
                              {truncateString(res?.name, 10)}
                            </Typography>
                            <IconButton
                              onClick={() => viewFileHandler(res)}
                              style={{
                                float: 'left',
                                color: 'orange'
                              }}
                              fontSize='small'
                            >
                              <VisibilityIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                              onClick={() => handleAttachmentDelete(res.id)}
                              // onClick={() =>
                              //   dispatch(GLAttachmentsDelete(index))
                              // }
                              style={{
                                float: 'right',
                                color: 'red'
                              }}
                              fontSize='small'
                            >
                              <HighlightOffIcon fontSize='small' />
                            </IconButton>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </>
                  ))}
                </GridContainer>
              </CardBody>
              <span>
                <Button
                  color='danger'
                  round
                  size='small'
                  style={{
                    width: 'fit-content',
                    float: 'right',
                    marginRight: '20px'
                  }}
                  className={classes.marginRight}
                  onClick={() => setAttachmentModal(false)}
                >
                  Close
                </Button>
              </span>
            </Card>
          </Dialog>
        ) : (
          ''
        )}
        <DialogActions>
          <Button
            color='primary'
            round
            style={{ float: 'right' }}
            className={classes.marginRight}
            disabled={isFormSubmitLoader}
            onClick={handleOnSubmitRequest}
          >
            {isFormSubmitLoader ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              'Submit'
            )}
          </Button>
          <Button
            color='danger'
            round
            style={{ float: 'right' }}
            className={classes.marginRight}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreatePO
