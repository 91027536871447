import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  MenuItem,
  Checkbox,
  Paper,
  Tabs,
  Tab
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
// @material-ui/icon
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import { useDispatch, useSelector } from 'react-redux'
import { setIsTokenExpired } from 'actions'
import Refresh from '@material-ui/icons/Refresh'
import AddAccount from '../AccountsMate_COA/addAccount'
import { addZeroes, errorAlert, successAlert } from '../../Functions/Functions'
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing'
import CallReceivedIcon from '@material-ui/icons/CallReceived'
import AccountPayable from './AccountPayable'
import AccountReceivable from './AccountReceivable'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {}
}

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export default function DefaultAccounts() {
  const classes = useStyles()
  const [coaList, setCoaList] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [value, setValue] = React.useState(0)
  const [payables, setPayables] = React.useState({
    prePayExp: '',
    prePayLib: '',
    receiptExp: '',
    receiptLib: '',
    pettyCashExp: '',
    pettyCashLib: ''
  })
  const [receiable, setReceivable] = React.useState({
    prePayAsset: '',
    prePayRev: '',
    receiptAsset: '',
    receiptRev: '',
    pettyCashAsset: '',
    pettyCashRev: ''
  })

  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')

  const dispatch = useDispatch()

  React.useEffect(() => {
    getCOA()
  }, [])

  function findAccount(list, id) {
    if (list?.length > 0 && id) {
      let findAcct = list.find((x) => x._id === id)
      if (findAcct) {
        let data = {
          id: findAcct._id,
          name: findAcct.name,
          code: findAcct.code
        }
        return data
      }
    } else {
      return ''
    }
  }

  //Get COA
  const getCOA = async () => {
    setIsLoading(true)
    await axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL
      }/lov/getListofChartsOfAccountwithoutGroup?search=${''}`,
      headers: { authorization: Token }
    })
      .then((response) => {
        setCoaList(response.data)
        setIsLoading(false)
        getSavedCOA(response.data)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        errorAlert(
          typeof error?.response != 'undefined'
            ? error?.response?.data
            : error?.message || 'Server Error!'
        )
        setIsLoading(false)
      })
  }

  //Get Saved COA
  const getSavedCOA = async (coaList) => {
    setIsLoading(true)
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/getCombinationCOA`,
      headers: { cooljwt: Token }
    })
      .then((response) => {
        setIsLoading(false)
        if (response.data?.length > 0) {
          let data = response.data[0]
          setPayables((payables) => ({
            ...payables,
            prePayExp: findAccount(
              coaList,
              data?.accountPayable?.prePayment?.expenseAcc?._id
            ),
            prePayLib: findAccount(
              coaList,
              data?.accountPayable?.prePayment?.liabilityAcc?._id
            ),
            receiptExp: findAccount(
              coaList,
              data?.accountPayable?.withReceipt?.expenseAcc?._id
            ),
            receiptLib: findAccount(
              coaList,
              data?.accountPayable?.withReceipt?.liabilityAcc?._id
            ),
            pettyCashExp: findAccount(
              coaList,
              data?.accountPayable?.pettyCash?.expenseAcc?._id
            ),
            pettyCashLib: findAccount(
              coaList,
              data?.accountPayable?.pettyCash?.liabilityAcc?._id
            )
          }))
          setReceivable((receiable) => ({
            ...receiable,
            prePayAsset: findAccount(
              coaList,
              data?.accountReceivable?.prePayment?.assetsAcc?._id
            ),
            prePayRev: findAccount(
              coaList,
              data?.accountReceivable?.prePayment?.IncomeAcc?._id
            ),
            receiptAsset: findAccount(
              coaList,
              data?.accountReceivable?.withReceipt?.assetsAcc?._id
            ),
            receiptRev: findAccount(
              coaList,
              data?.accountReceivable?.withReceipt?.IncomeAcc?._id
            ),
            pettyCashAsset: findAccount(
              coaList,
              data?.accountReceivable?.pettyCash?.assetsAcc?._id
            ),
            pettyCashRev: findAccount(
              coaList,
              data?.accountReceivable?.pettyCash?.IncomeAcc?._id
            )
          }))
        }
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        errorAlert(
          typeof error?.response != 'undefined'
            ? error?.response?.data
            : error?.message || 'Server Error!'
        )
        setIsLoading(false)
      })
  }

  //Save COA
  const saveCOADefault = async (type) => {
    setIsLoading(true)
    let data
    if (type === 'payable') {
      data = {
        isPaybale: true,
        prePayExp: payables.prePayExp?.id,
        prePayLib: payables.prePayLib?.id,
        receiptExp: payables.receiptExp?.id,
        receiptLib: payables.receiptLib?.id,
        pettyCashExp: payables.pettyCashExp?.id,
        pettyCashLib: payables.pettyCashLib?.id
      }
    } else {
      data = {
        isPaybale: false,
        prePayAsset: receiable.prePayAsset?.id,
        prePayRev: receiable.prePayRev?.id,
        receiptAsset: receiable.receiptAsset?.id,
        receiptRev: receiable.receiptRev?.id,
        pettyCashAsset: receiable.pettyCashAsset?.id,
        pettyCashRev: receiable.pettyCashRev?.id
      }
    }
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/SaveDefaultCombinationCOA`,
      headers: { cooljwt: Token },
      data: data
    })
      .then((response) => {
        setIsLoading(false)
        successAlert('Accounts Saved Successfully')
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        errorAlert(
          typeof error?.response != 'undefined'
            ? error?.response?.data
            : error?.message || 'Server Error!'
        )
        setIsLoading(false)
      })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div>
      <Animated
        animationIn='bounceInRight'
        animationOut='bounceOutLeft'
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Default Accounts</h4>
                </CardIcon>
              </CardHeader>
              <CardBody>
                <Paper square className={classes.root}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant='fullWidth'
                    indicatorColor='secondary'
                    textColor='secondary'
                    aria-label='icon label tabs example'
                  >
                    <Tab
                      icon={<CallMissedOutgoingIcon />}
                      label='Accounts Payable'
                    />
                    <Tab
                      icon={<CallReceivedIcon />}
                      label='Accounts Receivable'
                    />
                  </Tabs>
                </Paper>
                <br />
                <br />
                {value === 0 ? (
                  <AccountPayable
                    isLoading={isLoading}
                    saveCOADefault={saveCOADefault}
                    accountList={coaList}
                    state={payables}
                    setState={setPayables}
                  />
                ) : (
                  <AccountReceivable
                    isLoading={isLoading}
                    saveCOADefault={saveCOADefault}
                    accountList={coaList}
                    state={receiable}
                    setState={setReceivable}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  )
}
