import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import {
  TextField,
  MenuItem,
  Checkbox,
  IconButton,
  Switch
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Tooltip from '@material-ui/core/Tooltip'
// @material-ui/icon
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import jwt from 'jsonwebtoken'
import { useDispatch, useSelector } from 'react-redux'
import { setIsTokenExpired } from 'actions'
import Refresh from '@material-ui/icons/Refresh'
import AddAccount from './addAccount'
import { addZeroes, errorAlert, successAlert } from '../../Functions/Functions'
import Swal from 'sweetalert2'
import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import ReorderIcon from '@material-ui/icons/Reorder'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {}
}

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export default function COA() {
  const classes = useStyles()
  const [classicModal, setClassicModal] = React.useState(false)
  const [organizationFilter, setOrganizationFilter] = React.useState('')
  const [coaList, setCoaList] = React.useState([])
  const [viewModal, setViewModal] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [Updating, setUpdating] = React.useState(false)
  const [AccDetail, setAccDetail] = React.useState()
  const [viewGridTree, setView] = React.useState(true)
  const [decoded, setDecoded] = React.useState(null)
  const [data, setData] = React.useState()
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  let userDetail = jwt.decode(Token)
  let importInput = React.createRef()
  const dispatch = useDispatch()

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-primary',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: true
  })

  React.useEffect(() => {
    userDetail = jwt.decode(Token)
    setDecoded(userDetail)
    getCOA()
  }, [])

  const viewAccount = (row) => {
    setAccDetail(row)
    setUpdating(false)
    setViewModal(true)
  }

  const updateAcc = (row) => {
    setAccDetail(row)
    setUpdating(true)
    setViewModal(true)
  }

  const handleArchive = async (list, status) => {
    swalWithBootstrapButtons
      .fire({
        title: `Are you want ${status ? 'active' : 'inactive'}${' '}${
          list?.code
        }-${list?.name} account?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#5A2C66',
        cancelButtonText: 'No, cancel!',
        reverseButtons: false
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          var bodyFormData = {
            chartId: list?._id,
            Archive: status === false ? true : false,
            controlAccount: list.controlAccount,
            accountTypeID: list.accountTypeID,
            accountType: list.accountType,
            accountParentName: list.accountParentName,
            name: list.name,
            code: list.code,
            acctualCode: list.acctualCode,
            description: list.description,
            taxId: list.taxId,
            level: list.level
          }
          let msg = ''
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL}/chartsofAccounts/updateChartOfAccounts`,
            data: bodyFormData,
            headers: { authorization: Token }
          })
            .then((response) => {
              getCOA()
              msg = 'Status Updated Successfully!'
              successAlert(msg)
            })
            .catch((error) => {
              if (error.response) {
                error.response.status == 401 &&
                  dispatch(setIsTokenExpired(true))
              }
              msg =
                typeof error.response != 'undefined'
                  ? error.response.data
                  : error.message
              errorAlert(msg)
            })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
        }
      })
  }

  //Get COA
  const getCOA = () => {
    setIsLoading(true)
    axios({
      method: 'get',
      url: `${
        process.env.REACT_APP_LDOCS_ACCOUNTSMATE_API_URL
      }/chartsofAccounts/getListofChartsOfAccount?pageNumber=${0}&pageSize=${10}&type=${'All'}&search=${''}&Archive=${false}&paging=${false}`,
      headers: { authorization: Token }
    })
      .then((response) => {
        setCoaList(response.data)
        setData(
          response.data.map((prop, key) => {
            return {
              id: prop._id,
              Code: prop.code,
              Name: prop.name,
              AccountParentName: prop.accountParentName,
              Tax: prop?.Tax?.displayName,
              Status: prop?.Archive ? 'In-Active' : 'Active',
              actions: (
                <div className='actions-right'>
                  <Tooltip title='View Account' aria-label='viewlocation'>
                    <Button
                      justIcon
                      round
                      simple
                      icon={VisibilityIcon}
                      onClick={() => viewAccount(prop)}
                      color='warning'
                      className='View'
                    >
                      <VisibilityIcon />
                    </Button>
                  </Tooltip>
                </div>
              )
            }
          })
        )
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(
          typeof error.response != 'undefined'
            ? error.response.data
            : error.message
        )
        setIsLoading(false)
      })
  }

  const importTemp = (e) => {
    e.preventDefault()
    let file = e.target.files[0]
    let formData = new FormData()
    formData.append('file', file)
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/uploadAccountsSheet`,
      data: formData,
      headers: { cooljwt: Token }
    })
      .then(async (result) => {
        getCOA()
        successAlert('Imported Successfully')
      })
      .catch((error) => {
        errorAlert('Server Error')
      })
  }

  // const exportTOXlrs = () => {
  //   axios({
  //     method: "get", //you can set what request you want to be
  //     url: `${process.env.REACT_APP_LDOCS_API_URL}/tenant/exportAccountToXlxs`,
  //     headers: {
  //       cooljwt: Token,
  //       // responseType: 'blob',
  //     },
  //   })
  //     .then((response) => {
  //       console.log(response);
  //       const downloadUrl = `${process.env.REACT_APP_LDOCS_API_URL}/${response.data.path}`;
  //       const link = document.createElement("a");
  //       link.href = downloadUrl;
  //       link.setAttribute("download", ""); //any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   ChartOfAccountExcel(coaList);
  // };

  return (
    <div>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={12} md={12} className={classes.center}>
          <Dialog
            classes={{
              root: classes.center + ' ' + classes.modalRoot,
              paper: classes.modal
            }}
            fullWidth={true}
            maxWidth={'md'}
            open={classicModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setClassicModal(false)}
            aria-labelledby='classic-modal-slide-title'
            aria-describedby='classic-modal-slide-description'
          >
            <DialogContent
              id='classic-modal-slide-description'
              className={classes.modalBody}
            >
              <AddAccount
                closeModal={() => setClassicModal(false)}
                getCOA={getCOA}
                AccDetail={null}
                Updating={null}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            classes={{
              root: classes.center + ' ' + classes.modalRoot,
              paper: classes.modal
            }}
            fullWidth={true}
            maxWidth={'md'}
            open={viewModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setViewModal(false)}
            aria-labelledby='view-modal-slide-title'
            aria-describedby='view-modal-slide-description'
          >
            <DialogContent
              id='view-modal-slide-description'
              className={classes.modalBody}
            >
              {viewModal ? (
                <AddAccount
                  closeModal={() => setViewModal(false)}
                  getCOA={getCOA}
                  AccDetail={AccDetail}
                  Updating={Updating}
                />
              ) : (
                ''
              )}
            </DialogContent>
          </Dialog>
        </GridItem>
      </GridContainer>
      <Animated
        animationIn='bounceInRight'
        animationOut='bounceOutLeft'
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Chart of Account</h4>
                </CardIcon>
                {/* <Button
                  color="danger"
                  round
                  className={classes.marginRight}
                  style={{ float: "right", marginLeft: 20 }}
                  onClick={() => setClassicModal(true)}
                >
                  Add New
                </Button> */}
                {/* <Button
                  color={viewGridTree ? 'info' : "danger"}
                  round
                  style={{ float: 'right', height: '42px' }}
                  className={classes.marginRight}
                  onClick={() => setView(!viewGridTree)}
                >
                  {viewGridTree ? <ViewComfyIcon style={{ margin: '0px' }} /> : <ReorderIcon style={{ margin: '0px' }} />}
                </Button> */}
                {/* <Tooltip
                  id="tooltip-top"
                  title="Export to Excel"
                  style={{ float: "right" }}
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button
                    color="danger"
                    round
                    className={classes.marginRight}
                    // onClick={exportTOXlrs}
                  >
                    Export
                  </Button>
                </Tooltip>
                <div style={{ display: "flex", float: "right", flexDirection: "column", marginRight: 5 }}>
                  <Tooltip
                    id="tooltip-top"
                    title="Import from EXCLE"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      color="info"
                      round
                      className={classes.marginRight}
                      onClick={() => importInput.current.click()}
                    >
                      Import
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Template"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <a href={`${process.env.REACT_APP_LDOCS_API_URL}/invoicetemplates/Accounts.xlsx`} download target="_blank">Import Template</a>
                  </Tooltip> */}
                {/* </div> */}
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <CircularProgress disableShrink />
                ) : viewGridTree ? (
                  <ReactTable
                    data={data}
                    sortable={false}
                    columns={[
                      {
                        Header: 'Code',
                        accessor: 'Code'
                      },
                      {
                        Header: 'Name',
                        accessor: 'Name'
                      },
                      {
                        Header: 'Account Type',
                        accessor: 'AccountParentName'
                      },
                      {
                        Header: 'Tax',
                        accessor: 'Tax'
                      },
                      {
                        Header: 'Status',
                        accessor: 'Status',
                        width: 200
                      },
                      {
                        Header: 'Actions',
                        accessor: 'actions',
                        width: 100
                      }
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className='-striped -highlight'
                  />
                ) : (
                  ''
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem
            style={{
              marginTop: '20px'
            }}
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <div className='fileinput text-center'>
              <input
                type='file'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={importTemp}
                ref={importInput}
              />
            </div>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  )
}
