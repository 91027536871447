import React, { useEffect, useLayoutEffect, useState } from 'react'
import AdminLayout from 'layouts/Admin.js'
import ActionLayout from 'layouts/Action.js'
import InvoiceLayout from 'layouts/Invoice.js'
import VendorLayout from 'layouts/Vendor.js'
import AuthLayout from 'layouts/Auth.js'
import DefaultLayout from 'layouts/default'
import RtlLayout from 'layouts/RTL.js'
import InvoiceDetail from 'views/LDocs/Invoices/InvoiceDetail/InvoiceDetail'
import ActivateClient from 'views/LDocs/Clients/ActivateClient'
import AcknowledgedClient from 'views/LDocs/Clients/AcknowledgedClient'
import HomeLayout from 'layouts/Home.js'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
// import { createBrowserHistory } from 'history'
//  test
// ** Logout Request
import jwt from 'jsonwebtoken'
import { useSelector, useDispatch } from 'react-redux'
import axios from 'axios'
import DeviceUUID, { _IsAr } from './views/LDocs/Functions/Functions'
import { setToken } from 'actions'
import { logoutUserAction, getNotification, getTasks } from 'actions'

let uuid = new DeviceUUID().get()
const App = (props) => {
  const { hist } = props
  //   const hist = createBrowserHistory({ basename: '/pro' })
  const UrlLogoutToken = new URLSearchParams(window.location.search).get(
    'logout'
  )
  // ** states
  const [logoutCheck, setLogoutCheck] = useState(false)
  // ** redux & store
  const dispatch = useDispatch()
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const userData = jwt.decode(Token)

  // ** accounts mate logout token
  useLayoutEffect(() => {
    if (UrlLogoutToken && Token) {
      handleLogoutUser()
      // props.loginSuccess()
    }
  }, [dispatch])

  const handleLogoutUser = async () => {
    try {
      const usr = jwt.decode(Token)
      await axios({
        method: 'delete', //you can set what request you want to be
        url: usr.isVendor
          ? `${process.env.REACT_APP_LDOCS_API_URL}/vendor/deleteFcm`
          : `${process.env.REACT_APP_LDOCS_API_URL}/user/deleteUserFcm`,
        data: {
          deviceId: uuid
        },
        headers: {
          cooljwt: Token
        }
      })

      localStorage.clear()
      await dispatch(setToken(null))
      await dispatch(logoutUserAction())
      setLogoutCheck(true)
    } catch (err) {
      localStorage.clear()
      dispatch(setToken(null))
      dispatch(logoutUserAction())
      console.log(err)
    }
  }

  return (
    <Router history={hist}>
      <Switch>
        {/* If user is logged in Redirect user to DefaultRoute else to login */}
        {/* <Route path='/default' component={DefaultLayout} /> */}
        <Route path='/rtl' component={RtlLayout} />
        <Route path='/auth' component={AuthLayout} />
        {/* <Route path='/auth'>
          {userData ? <Redirect to='/default/home' /> : <AuthLayout />}
        </Route> */}
        <Route path='/default'>
          {userData ? <DefaultLayout /> : <Redirect to='/auth/login' />}
        </Route>
        {/*<Route path='/admin'>
          {userData ? <AdminLayout /> : <Redirect to='/auth/login' />}
        </Route>
        <Route path='/action'>
          {userData ? <ActionLayout /> : <Redirect to='/auth/login' />}
        </Route>
        <Route path='/vendor'>
          {userData ? <VendorLayout /> : <Redirect to='/auth/login' />}
        </Route>
        <Route path='/invoiceDetail'>
          {userData ? <InvoiceDetail /> : <Redirect to='/auth/login' />}
        </Route>
        <Route path='/client/activate'>
          {userData ? <ActivateClient /> : <Redirect to='/auth/login' />}
        </Route>
        <Route path='/client/acknowladged'>
          {userData ? <AcknowledgedClient /> : <Redirect to='/auth/login' />}
        </Route> */}
        <Route path='/admin' component={AdminLayout} />
        <Route path='/invoice' component={InvoiceLayout} />
        <Route path='/action' component={ActionLayout} />
        <Route path='/vendor' component={VendorLayout} />
        <Route path='/invoiceDetail' component={InvoiceDetail} />
        <Route path='/client/activate' component={ActivateClient} />
        <Route path='/client/acknowladged' component={AcknowledgedClient} />

        {/* <Redirect from="/" to="/admin/dashboard" />  */}
        {userData?.role?.isAdmin ? (
          <Redirect from='/' to='/admin/dashboard/ad' />
        ) : (
          <Redirect from='/' to='/default/home' />
        )}
      </Switch>
    </Router>
  )
}

export default App
