import React, { useState } from 'react'
// @material-ui/core components
import {
  TextField,
  makeStyles,
  Tooltip,
  IconButton,
  withStyles,
  MenuItem,
  Typography,
  Select,
  Input,
  Divider,
  ListSubheader,
  createMuiTheme,
  CircularProgress
} from '@material-ui/core'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'

// core components
import GridItem from 'components/Grid/GridItem.js'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CreateIcon from '@material-ui/icons/Create'
import { useDispatch, useSelector } from 'react-redux'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import axios from 'axios'
import { Visibility } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { setIsTokenExpired } from 'actions/index'
import { conversionRate } from 'views/LDocs/Functions/Functions'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  table: {
    minWidth: '100%'
  },
  itemName: {
    width: 300
  },
  itemNumber: {
    width: '55%'
  },
  taxField: {
    width: 100
  }
}
const useStyles = makeStyles(styles)

// textfield asterik style
const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        }
      }
    }
  }
})

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.action.hover
    // color: theme.palette.common.black,
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  root: {
    // "&:nth-of-type(odd)": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  }
}))(TableRow)

export default function Items(props) {
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const dispatch = useDispatch()
  const Check = require('is-null-empty-or-undefined').Check

  let {
    formState,
    items,
    addZeroes,
    handleChange,
    handleCOAChange,
    handleEditItem,
    removeItem,
    addInvoiceItem,
    addItem,
    editIndex,
    editItem,
    addingItem,
    addingItemloading,
    setAddingItem,
    setCategory,
    setEditIndex,
    category,
    currency,
    viewPO,
    pos,
    isVendor,
    createReceipts,
    receipts,
    currencyLookups,
    baseCurrency,
    userData,
    edit,
    isAr
  } = props
  const classes = useStyles()
  const [lookups, setLookups] = useState([])
  const [baseCurr, setBaseCurr] = useState()

  //getLookups
  const getLookUp = async () => {
    return new Promise((res, rej) => {
      axios({
        method: 'get', //you can set what request you want to be
        url: `${process.env.REACT_APP_LDOCS_API_URL}/lookup/getLookups/3`,
        headers: {
          cooljwt: Token
        }
      })
        .then((response) => {
          setLookups(response.data.result)
        })
        .catch((error) => {
          if (error.response) {
            error.response.status == 401 && dispatch(setIsTokenExpired(true))
          }
          console.log(error)
          rej([])
        })
    })
  }

  React.useEffect(() => {
    getLookUp()
  }, [])

  React.useEffect(() => {
    setBaseCurr(
      baseCurrency
        ? baseCurrency
        : !isVendor
        ? userData.currency.Currency_Base
        : formState.selectedOrg
        ? formState.selectedOrg.currency
        : ''
    )
  }, [formState])

  function calculateUnitCost(quantity, price, discountPercent, taxRate) {
    const totalCost = quantity * price
    const discountAmount = totalCost * (discountPercent / 100)
    const discountedPrice = totalCost - discountAmount
    const taxAmount = discountedPrice * (taxRate / 100)
    const totalCostWithTax = discountedPrice + taxAmount
    // const unitCost = totalCostWithTax / quantity;

    return totalCostWithTax
  }

  return (
    <GridItem xs={12} sm={12} md={12} lg={12}>
      <TableContainer
        component={Paper}
        style={{
          borderBottom:
            formState.errors.items === 'error' ? '2px red solid' : 'none'
        }}
      >
        <Table className={classes.table} aria-label='customized table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell align='left'>
                Unit Cost ({currency.Code || '$'})
              </StyledTableCell>
              <StyledTableCell align='left'>Quantity</StyledTableCell>
              {userData?.tenantPermissions?.enableAccounts && (
                <StyledTableCell align='left'>Tax</StyledTableCell>
              )}
              <StyledTableCell align='left'>Discount (%)</StyledTableCell>
              <StyledTableCell align='left'>
                Amount ({currency.Code || '$'})
              </StyledTableCell>
              <StyledTableCell> </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((row, index) =>
              editIndex == index ? (
                <React.Fragment>
                  <StyledTableRow key={'AddingItem'}>
                    <StyledTableCell style={{ width: 100 }}>
                      <TextField
                        fullWidth={true}
                        label='ID '
                        type='number'
                        disabled={true}
                        value={index + 1 || ''}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ minWidth: 300 }}
                      component='th'
                      scope='row'
                    >
                      <TextField
                        fullWidth={true}
                        error={formState.errors.itemName === 'error'}
                        helperText={
                          formState.errors.itemName === 'error'
                            ? 'Valid Item Name is required'
                            : null
                        }
                        label='Item Name'
                        id='item'
                        name='itemName'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='text'
                        value={formState.values.itemName || ''}
                        className={classes.itemName}
                      />
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      <TextField
                        fullWidth={true}
                        error={formState.errors.unitCost === 'error'}
                        helperText={
                          formState.errors.unitCost === 'error'
                            ? 'Valid Unit Cost is required'
                            : null
                        }
                        label='Unit Cost'
                        id='unitcost'
                        name='unitCost'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='number'
                        value={formState.values.unitCost || ''}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      <TextField
                        fullWidth={true}
                        error={formState.errors.quantity === 'error'}
                        helperText={
                          formState.errors.quantity === 'error'
                            ? 'Valid Quantity is required'
                            : null
                        }
                        label='Quantity'
                        id='quantity'
                        name='quantity'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='number'
                        value={formState.values.quantity || ''}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    {userData?.tenantPermissions?.enableAccounts && (
                      <StyledTableCell align='right'>
                        <MuiThemeProvider theme={formLabelsTheme}>
                          <TextField
                            fullWidth={true}
                            label='Tax'
                            id='taxId'
                            name='taxId'
                            onChange={(event) => {
                              handleChange(event)
                            }}
                            select
                            value={formState.values.taxId || ''}
                            className={classes.taxField}
                          >
                            <MenuItem disabled={true} key={'disabled'}>
                              Choose Tax
                            </MenuItem>
                            {formState?.taxes?.map((tax, index) => {
                              return (
                                <MenuItem key={index} value={tax._id}>
                                  {tax?.displayName}
                                </MenuItem>
                              )
                            })}
                          </TextField>
                        </MuiThemeProvider>
                      </StyledTableCell>
                    )}
                    <StyledTableCell align='right'>
                      <TextField
                        fullWidth={true}
                        error={formState.errors.discount === 'error'}
                        helperText={
                          formState.errors.discount === 'error'
                            ? 'Valid Discount is required'
                            : null
                        }
                        label='Discount '
                        id='discount'
                        name='discount'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='number'
                        value={addZeroes(formState.values.discount) || 0.0}
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      <TextField
                        fullWidth={true}
                        label='Amount'
                        id='item'
                        name='amount'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        disabled={true}
                        type='number'
                        value={
                          addZeroes(
                            calculateUnitCost(
                              parseInt(formState.values.quantity),
                              parseInt(formState.values.unitCost),
                              parseInt(formState.values.discount),
                              parseInt(formState.values.taxValue)
                            )
                          ) || 0.0
                        }
                        className={classes.textField}
                      />
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 100 }} align='right'>
                      <Tooltip title='Save Chnages' aria-label='save'>
                        <IconButton onClick={() => editItem(index)}>
                          <CheckCircleOutlineIcon
                            style={{ color: 'green' }}
                            size='small'
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Close' aria-label='close'>
                        <IconButton onClick={() => setEditIndex(null)}>
                          <HighlightOffIcon
                            style={{ color: 'red' }}
                            size='small'
                          />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow key={'AddingItem1'}>
                    {!isVendor && formState.isReceipt && formState.isPo ? (
                      <React.Fragment>
                        <StyledTableCell colSpan='6'>
                          <Select
                            className={classes.textField}
                            multiple
                            fullWidth={true}
                            error={formState.errors.receiptNumber === 'error'}
                            helperText={
                              formState.errors.receiptNumber === 'error'
                                ? 'Valid Receipt Number required'
                                : null
                            }
                            label='Receipt Number'
                            id='receiptNumber'
                            name='receiptNumber'
                            onChange={(event) => {
                              handleChange(event)
                            }}
                            // variant="outlined"
                            value={formState.values.receiptNumber || []}
                            input={<Input />}
                            // MenuProps={MenuProps}
                            select
                          >
                            <MenuItem disabled={true} key={'disabled'}>
                              Receipt Number
                            </MenuItem>
                            {receipts.map((r, index) => {
                              return (
                                <MenuItem key={index} value={r.receiptNumber}>
                                  {r.receiptNumber}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </StyledTableCell>
                        <StyledTableCell style={{ paddingTop: 30 }} colSpan='1'>
                          <Tooltip title='Create Receipt'>
                            <IconButton
                              onClick={createReceipts}
                              style={{ background: 'lightgrey' }}
                            >
                              <AddCircleOutlineIcon fontSize='small' />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </StyledTableRow>
                  <StyledTableRow key={'AddingItem2'}>
                    {isAr ? (
                      <>
                        <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                          <Autocomplete
                            id='parentAccount_auto_complete'
                            options={
                              formState.allCOATypes.filter(
                                (x) => x.accountParentName === 'Assets'
                              ) || []
                            }
                            size='small'
                            value={row?.assetAcct || ''}
                            getOptionLabel={(option) =>
                              (option !== '' &&
                                ` ${option?.code} - ${option?.name}`) ||
                              ''
                            }
                            // loading={isLoading}
                            onChange={(event, newValue) => {
                              handleCOAChange(
                                formState.isPrePayment
                                  ? 'prePayAsset'
                                  : formState.isReceipt
                                  ? 'receiptAsset'
                                  : 'pettyCashAsset',
                                newValue
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name='coaId'
                                label='Asset Account'
                                variant='standard'
                                // error={state.prePayAsset?.errorCoa === 'error'}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: 'black' },
                                  classes: {
                                    asterisk: classes.asterisk
                                  }
                                }}
                                value={row?.assetAcct || ''}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </StyledTableCell>
                        <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                          <Autocomplete
                            id='parentAccount_auto_complete'
                            options={
                              formState.allCOATypes.filter(
                                (x) => x.accountParentName === 'Revenue'
                              ) || []
                            }
                            size='small'
                            value={row?.revenueAcct || ''}
                            getOptionLabel={(option) =>
                              (option !== '' &&
                                ` ${option?.code} - ${option?.name}`) ||
                              ''
                            }
                            // loading={isLoading}
                            onChange={(event, newValue) => {
                              handleCOAChange(
                                formState.isPrePayment
                                  ? 'prePayRev'
                                  : formState.isReceipt
                                  ? 'receiptRev'
                                  : 'pettyCashRev',
                                newValue
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name='coaId'
                                label='Revenue Account'
                                variant='standard'
                                // error={row?.errorCoa === 'error'}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: 'black' },
                                  classes: {
                                    asterisk: classes.asterisk
                                  }
                                }}
                                required
                                value={row?.revenueAcct || ''}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </StyledTableCell>
                      </>
                    ) : (
                      <>
                        <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                          <Autocomplete
                            id='parentAccount_auto_complete'
                            options={
                              formState.allCOATypes.filter(
                                (x) => x.accountParentName === 'Expenses'
                              ) || []
                            }
                            size='small'
                            value={row?.expenseAcct || ''}
                            getOptionLabel={(option) =>
                              (option !== '' &&
                                ` ${option?.code} - ${option?.name}`) ||
                              ''
                            }
                            // loading={isLoading}
                            onChange={(event, newValue) => {
                              handleCOAChange(
                                formState.isPrePayment
                                  ? 'prePayExp'
                                  : formState.isReceipt
                                  ? 'receiptExp'
                                  : 'pettyCashExp',
                                newValue
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name='coaId'
                                label='Expense Account'
                                variant='standard'
                                // error={state.prePayExp?.errorCoa === 'error'}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: 'black' },
                                  classes: {
                                    asterisk: classes.asterisk
                                  }
                                }}
                                value={row?.expenseAcct || ''}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </StyledTableCell>
                        <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                          <Autocomplete
                            id='parentAccount_auto_complete'
                            options={
                              formState.allCOATypes.filter(
                                (x) => x.accountParentName === 'Liabilities'
                              ) || []
                            }
                            size='small'
                            value={row?.libailityAcct || ''}
                            getOptionLabel={(option) =>
                              (option !== '' &&
                                ` ${option?.code} - ${option?.name}`) ||
                              ''
                            }
                            // loading={isLoading}
                            onChange={(event, newValue) => {
                              handleCOAChange(
                                formState.isPrePayment
                                  ? 'prePayLib'
                                  : formState.isReceipt
                                  ? 'receiptLib'
                                  : 'pettyCashLib',
                                newValue
                              )
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name='coaId'
                                label='Liability Account'
                                variant='standard'
                                // error={state.prePayExp?.errorCoa === 'error'}
                                InputLabelProps={{
                                  shrink: true,
                                  style: { color: 'black' },
                                  classes: {
                                    asterisk: classes.asterisk
                                  }
                                }}
                                value={row?.libailityAcct || ''}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <React.Fragment>
                                      {params.InputProps.endAdornment}
                                    </React.Fragment>
                                  )
                                }}
                              />
                            )}
                          />
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                  <StyledTableRow key={'AddingItem2'}>
                    <StyledTableCell
                      colSpan={
                        (!isVendor && formState.isPo) || formState.isPeetyCash
                          ? formState.isPrePayment && formState.isPo
                            ? '8'
                            : '5'
                          : '8'
                      }
                    >
                      <TextField
                        fullWidth={true}
                        error={formState.errors.additionalDetails === 'error'}
                        helperText={
                          formState.errors.additionalDetails === 'error'
                            ? 'Valid Additional Details is required'
                            : null
                        }
                        label='Additonal Details'
                        id='additionaldetails'
                        name='additionalDetails'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        multiline
                        rows={1}
                        type='text'
                        value={formState.values.additionalDetails || ''}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <StyledTableRow key={row.itemName}>
                    <StyledTableCell style={{ width: 100 }}>
                      {row.receiptNumber.length < 1 &&
                      !isVendor &&
                      formState.isReceipt &&
                      formState.isPo ? (
                        <Tooltip
                          title='Receipt Number is Missing..'
                          aria-label='edit'
                        >
                          <IconButton
                            onClick={() => handleEditItem(row, index)}
                          >
                            <ErrorOutlineIcon
                              fontSize='small'
                              style={{ color: 'orange' }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        index + 1
                      )}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ width: 400 }}
                      component='th'
                      scope='row'
                    >
                      {row.itemName}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {`${currency.Code} `}
                      {addZeroes(row.unitCost)}
                      <br />
                      {currency._id != baseCurr &&
                        conversionRate(
                          currency._id,
                          baseCurr,
                          currencyLookups,
                          row.unitCost,
                          false,
                          edit,
                          formState.conversionRate
                            ? formState.conversionRate
                            : 0
                        )}
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {addZeroes(row.quantity)}
                    </StyledTableCell>
                    {userData?.tenantPermissions?.enableAccounts && (
                      <StyledTableCell align='left'>
                        {row.taxAmt}%
                      </StyledTableCell>
                    )}
                    <StyledTableCell align='left'>
                      {addZeroes(row.discount)}%
                    </StyledTableCell>
                    <StyledTableCell align='left'>
                      {`${currency.Code} `}
                      {addZeroes(row.amount)}
                      <br />
                      {currency._id != baseCurr &&
                        conversionRate(
                          currency._id,
                          baseCurrency
                            ? baseCurrency
                            : !isVendor
                            ? userData.currency.Currency_Base
                            : formState.selectedOrg
                            ? formState.selectedOrg.currency
                            : '',
                          currencyLookups,
                          row.amount,
                          false,
                          edit,
                          formState.conversionRate
                            ? formState.conversionRate
                            : 0
                        )}
                    </StyledTableCell>
                    <StyledTableCell style={{ width: 100 }} align='left'>
                      <Tooltip title='Edit Item' aria-label='edit'>
                        <IconButton onClick={() => handleEditItem(row, index)}>
                          <CreateIcon
                            style={{ color: 'orange' }}
                            size='small'
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Remove Item' aria-label='add'>
                        <IconButton onClick={() => removeItem(index)}>
                          <HighlightOffIcon
                            style={{ color: 'red' }}
                            size='small'
                          />
                        </IconButton>
                      </Tooltip>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              )
            )}

            {addingItem ? (
              <React.Fragment>
                <StyledTableRow key={'AddingItem'}>
                  <StyledTableCell style={{ width: 100 }}>
                    <TextField
                      fullWidth={true}
                      label='ID '
                      type='number'
                      disabled={true}
                      value={items.length + 1 || ''}
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    style={{ minWidth: 300 }}
                    component='th'
                    scope='row'
                  >
                    <MuiThemeProvider theme={formLabelsTheme}>
                      <TextField
                        required
                        fullWidth={true}
                        error={formState.errors.itemName === 'error'}
                        helperText={
                          formState.errors.itemName === 'error'
                            ? 'Valid Item Name is required'
                            : null
                        }
                        label='Item Name '
                        id='item'
                        name='itemName'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='text'
                        value={formState.values.itemName || ''}
                        className={classes.itemName}
                      />
                    </MuiThemeProvider>
                  </StyledTableCell>

                  <StyledTableCell align='right'>
                    <MuiThemeProvider theme={formLabelsTheme}>
                      <TextField
                        required
                        fullWidth={true}
                        error={formState.errors.unitCost === 'error'}
                        helperText={
                          formState.errors.unitCost === 'error'
                            ? 'Valid Unit Cost is required'
                            : null
                        }
                        label='Unit Cost'
                        id='unitcost'
                        name='unitCost'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='number'
                        value={formState.values.unitCost || ''}
                        className={classes.textField}
                      />
                    </MuiThemeProvider>
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    <MuiThemeProvider theme={formLabelsTheme}>
                      <TextField
                        required
                        fullWidth={true}
                        error={formState.errors.quantity === 'error'}
                        helperText={
                          formState.errors.quantity === 'error'
                            ? 'Valid Quantity is required'
                            : null
                        }
                        label='Quantity'
                        id='quantity'
                        name='quantity'
                        onChange={(event) => {
                          handleChange(event)
                        }}
                        type='number'
                        value={formState.values.quantity || ''}
                        className={classes.textField}
                      />
                    </MuiThemeProvider>
                  </StyledTableCell>
                  {userData?.tenantPermissions?.enableAccounts && (
                    <StyledTableCell align='right'>
                      <MuiThemeProvider theme={formLabelsTheme}>
                        <TextField
                          fullWidth={true}
                          label='Tax'
                          id='taxId'
                          name='taxId'
                          onChange={(event) => {
                            handleChange(event)
                          }}
                          select
                          value={formState.values.taxId || ''}
                          className={classes.taxField}
                        >
                          <MenuItem disabled={true} key={'disabled'}>
                            Choose Tax
                          </MenuItem>
                          {formState?.taxes?.map((tax, index) => {
                            return (
                              <MenuItem key={index} value={tax._id}>
                                {tax?.displayName}
                              </MenuItem>
                            )
                          })}
                        </TextField>
                      </MuiThemeProvider>
                    </StyledTableCell>
                  )}
                  <StyledTableCell align='right'>
                    <TextField
                      fullWidth={true}
                      error={formState.errors.discount === 'error'}
                      helperText={
                        formState.errors.discount === 'error'
                          ? 'Valid Discount is required'
                          : null
                      }
                      label='Discount '
                      id='discount'
                      name='discount'
                      onChange={(event) => {
                        handleChange(event)
                      }}
                      type='number'
                      value={formState.values.discount || 0.0}
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell align='right'>
                    <TextField
                      fullWidth={true}
                      label='Amount'
                      id='item'
                      name='amount'
                      onChange={(event) => {
                        handleChange(event)
                      }}
                      disabled={true}
                      type='number'
                      value={
                        addZeroes(
                          calculateUnitCost(
                            parseInt(formState.values.quantity),
                            parseInt(formState.values.unitCost),
                            parseInt(formState.values.discount),
                            parseInt(formState.values.taxValue)
                          )
                        ) || 0.0
                      }
                      className={classes.textField}
                    />
                  </StyledTableCell>
                  <StyledTableCell style={{ width: 100 }} align='right'>
                    <Tooltip title='Save Chnages' aria-label='save'>
                      <IconButton onClick={addItem}>
                        <CheckCircleOutlineIcon
                          style={{ color: 'green' }}
                          size='small'
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Remove Item' aria-label='remove'>
                      <IconButton onClick={() => setAddingItem(false)}>
                        <HighlightOffIcon
                          style={{ color: 'red' }}
                          size='small'
                        />
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
                {!isVendor && formState.isReceipt && formState.isPo ? (
                  <StyledTableRow key={'AddingItem1'}>
                    <React.Fragment>
                      <StyledTableCell style={{ paddingTop: 30 }} colSpan='6'>
                        <Select
                          className={classes.textField}
                          multiple
                          fullWidth={true}
                          error={formState.errors.receiptNumber === 'error'}
                          helperText={
                            formState.errors.receiptNumber === 'error'
                              ? 'Valid Receipt Number required'
                              : null
                          }
                          label='Receipt Number'
                          id='receiptNumber'
                          name='receiptNumber'
                          onChange={(event) => {
                            handleChange(event)
                          }}
                          // variant="outlined"
                          value={formState.values.receiptNumber || []}
                          input={<Input />}
                          // MenuProps={MenuProps}
                          select
                        >
                          <MenuItem disabled={true} key={'disabled'}>
                            Receipt Number
                          </MenuItem>
                          {receipts.map((r, index) => {
                            return (
                              <MenuItem key={index} value={r.receiptNumber}>
                                {r.receiptNumber}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingTop: 30 }} colSpan='1'>
                        <Tooltip
                          onClick={createReceipts}
                          title='Create Receipt'
                        >
                          <IconButton style={{ background: 'lightgrey' }}>
                            <AddCircleOutlineIcon fontSize='small' />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    </React.Fragment>
                  </StyledTableRow>
                ) : (
                  ''
                )}
                <StyledTableRow>
                  {isAr ? (
                    <>
                      <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                        <Autocomplete
                          id='parentAccount_auto_complete'
                          options={
                            formState.allCOATypes.filter(
                              (x) => x.accountParentName === 'Assets'
                            ) || []
                          }
                          size='small'
                          value={
                            formState.isPrePayment
                              ? formState.COATypes.receiable.prePayAsset
                              : formState.isReceipt
                              ? formState.COATypes.receiable.receiptAsset
                              : formState.COATypes.receiable.pettyCashAsset ||
                                ''
                          }
                          getOptionLabel={(option) =>
                            (option !== '' &&
                              ` ${option?.code} - ${option?.name}`) ||
                            ''
                          }
                          // loading={isLoading}
                          onChange={(event, newValue) => {
                            handleCOAChange(
                              formState.isPrePayment
                                ? 'prePayAsset'
                                : formState.isReceipt
                                ? 'receiptAsset'
                                : 'pettyCashAsset',
                              newValue
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='coaId'
                              label='Asset Account'
                              variant='standard'
                              // error={state.prePayAsset?.errorCoa === 'error'}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: 'black' },
                                classes: {
                                  asterisk: classes.asterisk
                                }
                              }}
                              value={
                                formState.isPrePayment
                                  ? formState.COATypes.receiable.prePayAsset
                                  : formState.isReceipt
                                  ? formState.COATypes.receiable.receiptAsset
                                  : formState.COATypes.receiable
                                      .pettyCashAsset || ''
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                        <Autocomplete
                          id='parentAccount_auto_complete'
                          options={
                            formState.allCOATypes.filter(
                              (x) => x.accountParentName === 'Revenue'
                            ) || []
                          }
                          size='small'
                          value={
                            formState.isPrePayment
                              ? formState.COATypes.receiable.prePayRev
                              : formState.isReceipt
                              ? formState.COATypes.receiable.receiptRev
                              : formState.COATypes.receiable.pettyCashRev || ''
                          }
                          getOptionLabel={(option) =>
                            (option !== '' &&
                              ` ${option?.code} - ${option?.name}`) ||
                            ''
                          }
                          // loading={isLoading}
                          onChange={(event, newValue) => {
                            handleCOAChange(
                              formState.isPrePayment
                                ? 'prePayRev'
                                : formState.isReceipt
                                ? 'receiptRev'
                                : 'pettyCashRev',
                              newValue
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='coaId'
                              label='Revenue Account'
                              variant='standard'
                              // error={row?.errorCoa === 'error'}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: 'black' },
                                classes: {
                                  asterisk: classes.asterisk
                                }
                              }}
                              required
                              value={
                                formState.isPrePayment
                                  ? formState.COATypes.receiable.prePayRev
                                  : formState.isReceipt
                                  ? formState.COATypes.receiable.receiptRev
                                  : formState.COATypes.receiable.pettyCashRev ||
                                    ''
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </StyledTableCell>
                    </>
                  ) : (
                    <>
                      <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                        <Autocomplete
                          id='parentAccount_auto_complete'
                          options={
                            formState.allCOATypes.filter(
                              (x) => x.accountParentName === 'Expenses'
                            ) || []
                          }
                          size='small'
                          value={
                            formState.isPrePayment
                              ? formState.COATypes.payables.prePayExp
                              : formState.isReceipt
                              ? formState.COATypes.payables.receiptExp
                              : formState.COATypes.payables.pettyCashExp || ''
                          }
                          getOptionLabel={(option) =>
                            (option !== '' &&
                              ` ${option?.code} - ${option?.name}`) ||
                            ''
                          }
                          // loading={isLoading}
                          onChange={(event, newValue) => {
                            handleCOAChange(
                              formState.isPrePayment
                                ? 'prePayExp'
                                : formState.isReceipt
                                ? 'receiptExp'
                                : 'pettyCashExp',
                              newValue
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='coaId'
                              label='Expense Account'
                              variant='standard'
                              // error={state.prePayExp?.errorCoa === 'error'}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: 'black' },
                                classes: {
                                  asterisk: classes.asterisk
                                }
                              }}
                              value={
                                formState.isPrePayment
                                  ? formState.COATypes.payables.prePayExp
                                  : formState.isReceipt
                                  ? formState.COATypes.payables.receiptExp
                                  : formState.COATypes.payables.pettyCashExp ||
                                    ''
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </StyledTableCell>
                      <StyledTableCell style={{ paddingTop: 20 }} colSpan='3'>
                        <Autocomplete
                          id='parentAccount_auto_complete'
                          options={
                            formState.allCOATypes.filter(
                              (x) => x.accountParentName === 'Liabilities'
                            ) || []
                          }
                          size='small'
                          value={
                            formState.isPrePayment
                              ? formState.COATypes.payables.prePayLib
                              : formState.isReceipt
                              ? formState.COATypes.payables.receiptLib
                              : formState.COATypes.payables.pettyCashLib || ''
                          }
                          getOptionLabel={(option) =>
                            (option !== '' &&
                              ` ${option?.code} - ${option?.name}`) ||
                            ''
                          }
                          // loading={isLoading}
                          onChange={(event, newValue) => {
                            handleCOAChange(
                              formState.isPrePayment
                                ? 'prePayLib'
                                : formState.isReceipt
                                ? 'receiptLib'
                                : 'pettyCashLib',
                              newValue
                            )
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name='coaId'
                              label='Liability Account'
                              variant='standard'
                              // error={state.prePayExp?.errorCoa === 'error'}
                              InputLabelProps={{
                                shrink: true,
                                style: { color: 'black' },
                                classes: {
                                  asterisk: classes.asterisk
                                }
                              }}
                              value={
                                formState.isPrePayment
                                  ? formState.COATypes.payables.prePayLib
                                  : formState.isReceipt
                                  ? formState.COATypes.payables.receiptLib
                                  : formState.COATypes.payables.pettyCashLib ||
                                    ''
                              }
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                )
                              }}
                            />
                          )}
                        />
                      </StyledTableCell>
                    </>
                  )}
                </StyledTableRow>
                <StyledTableRow>
                  <StyledTableCell
                    colSpan={
                      (!isVendor && formState.isPo) || formState.isPeetyCash
                        ? formState.isPrePayment && formState.isPo
                          ? '8'
                          : '5'
                        : '8'
                    }
                  >
                    <TextField
                      fullWidth={true}
                      error={formState.errors.additionalDetails === 'error'}
                      helperText={
                        formState.errors.additionalDetails === 'error'
                          ? 'Valid Additional Details is required'
                          : null
                      }
                      label='Additonal Details'
                      id='additionaldetails'
                      name='additionalDetails'
                      onChange={(event) => {
                        handleChange(event)
                      }}
                      multiline
                      rows={1}
                      type='text'
                      value={formState.values.additionalDetails || ''}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              </React.Fragment>
            ) : (
              ''
            )}
            {!addingItem && editIndex == null ? (
              <StyledTableRow key={'AddItem'}>
                <StyledTableCell colSpan='8' align='center'>
                  <Tooltip title='Add Item' aria-label='add'>
                    {/* <Fab color="secoundry" className={classes.fab}> */}
                    <IconButton onClick={addInvoiceItem}>
                      {addingItemloading ? (
                        <CircularProgress />
                      ) : (
                        <AddCircleOutlineIcon />
                      )}
                    </IconButton>
                    {/* </Fab> */}
                  </Tooltip>
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              ''
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </GridItem>
  )
}
