import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import { store, persistor } from './redux'
import { PersistGate } from 'redux-persist/integration/react'
import addNotification from 'react-push-notification'

// import AuthLayout from "layouts/Auth.js";
// import RtlLayout from "layouts/RTL.js";

import 'assets/scss/material-dashboard-pro-react.scss?v=1.8.0'
import { registerServiceWorker } from './register-sw'
import App from 'App'

navigator.serviceWorker.addEventListener('notificationclick', (message) => {
  console.log(message)
}) 
const loader = document.querySelector('.loader')

// if you want to show the loader when React loads data again
const showLoader = () => loader.classList.remove('loader--hide')

const hideLoader = () => loader.classList.add('loader--hide')
navigator.serviceWorker.addEventListener('message', (message) => {
  var payload = message.data['firebase-messaging-msg-data']
  addNotification({
    title: payload.notification.title,
    message: payload.notification.body, //optional
    duration: 6000, //optional, default: 5000,
    native: true, //optional, makes the push notification a native OS notification
    icon: 'https://i.ibb.co/k409jfP/logo.png', // optional, Native only. Sets an icon for the notification.
    vibrate: 4, // optional, Native only. Sets a vibration for the notification.
    silent: false, // o
    onClick: () => window.open(payload.data.url)
  })
})

const hist = createBrowserHistory({ basename: '/pro' })
registerServiceWorker()
hideLoader()
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App hist={hist} />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
