import Swal from 'sweetalert2'
// import withReactContent from 'sweetalert2-react-content'
import './custom-sweetalert.scss'

// const MySwal = withReactContent(Swal)

const AuthPermissionsAlert = () => {
  const buttonStyle = {
    backgroundColor: 'blue',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    border: 'none'
  }
  return Swal.fire({
    icon: 'warning',
    title: 'Not Authorized!',
    text: `You are not authorized to access this functionality. Please contact system administrator.`,
    confirmButtonText: 'Back To Home',
    buttonsStyling: false,
    customClass: {
      container: 'dark_temp_sweetAlert',
      confirmButton: 'my-button-class' // Replace with your desired button color class
    }
  })
}

export default AuthPermissionsAlert
