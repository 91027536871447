import React from 'react'
import Button from 'components/CustomButtons/Button.js'
// import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
// import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import RIStatusTable from '../tables'
import { Slide, Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react'

import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'

const styles = makeStyles((theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  },
  buttonRight: {},
  table: {
    minWidth: '100%'
  },
  itemName: {
    width: 400
  },
  itemNumber: {
    width: '55%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

export default function StatusTableModal(props) {
  const classes = styles()
  const { isStatusTableModalOpen, setStatusTableModal, trackingStatus } = props
  //   const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setStatusTableModal(true)
  }

  const handleClose = () => {
    setStatusTableModal(false)
  }

  return (
    <React.Fragment>
      <Dialog
        classes={{
          root: classes.center + ' ' + classes.modalRoot,
          paper: classes.modal
        }}
        fullWidth={true}
        maxWidth='md'
        open={isStatusTableModalOpen}
        keepMounted
        onClose={handleClose}
        aria-labelledby='selected-modal-slide-title'
        aria-describedby='selected-modal-slide-description'
      >
        {/* <DialogTitle id='max-width-dialog-title'>Optional sizes</DialogTitle> */}
        <DialogContent id='selected-Select' className={classes.modalBody}>
          <Card>
            <CardHeader color='info' icon>
              <CardIcon color='info'>
                <h4 className={classes.cardTitleText}>Tracking Status</h4>
              </CardIcon>
            </CardHeader>
            <CardBody>
              <RIStatusTable data={trackingStatus} />
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
