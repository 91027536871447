import React, { useState, useEffect } from 'react'
// @material-ui/icons
import { makeStyles, TextField, Box } from '@material-ui/core'
// core components
import { Animated } from 'react-animated-css'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Typography from '@material-ui/core/Typography'
import AttachmentIcon from '@material-ui/icons/Attachment'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import Card from 'components/Card/Card.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js'
import styles2 from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader.js'
import CardIcon from 'components/Card/CardIcon.js'
import Button from 'components/CustomButtons/Button.js'

const useStyles = makeStyles(styles)
const sweetAlertStyle = makeStyles(styles2)

export default function Step3(props) {
  const classes = useStyles()
  const [animateStep, setAnimateStep] = useState(true)
  const [viewFile, setViewFile] = useState(false)
  const [imgFile, setImgFile] = useState()

  const handleFileViewer = (file) => {
    setViewFile(true)
    setAnimateStep(false)
    setImgFile(file)
  }
  const closeFileViewer = (image) => {
    setViewFile(false)
    setAnimateStep(true)
  }
  return (
    <>
      {animateStep ? (
        <Animated
          animationIn='bounceInRight'
          animationOut='bounceOutLeft'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={animateStep}
        >
          {props.attachments.length > 0 ? (
            <GridContainer style={{ paddingTop: 20 }}>
              {props.attachments.map((att, index) => (
                <GridItem xs={12} sm={3} md={2} lg={2}>
                  {/* <a
              style={{ color: 'grey' }}
              href={`${process.env.REACT_APP_LDOCS_API_URL}/${att.attachmentPath}`}
              target='_blank'
            > */}
                  {console.log(att)}
                  <Card
                    style={{
                      padding: '10px',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}
                    onClick={() => handleFileViewer(att)}
                  >
                    <AttachmentIcon
                      style={{
                        alignSelf: 'center',
                        width: 50,
                        height: 50,
                        marginBottom: 10
                      }}
                      fontSize='large'
                    />
                    <Typography variant='body2' component='h6'>
                      {att.attachmentTitle}
                    </Typography>
                  </Card>
                  {/* </a> */}
                </GridItem>
              ))}
            </GridContainer>
          ) : (
            ''
          )}
        </Animated>
      ) : (
        ''
      )}
      {viewFile ? (
        <Animated
          animationIn='bounceInRight'
          animationOut='bounceOutLeft'
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={viewFile}
        >
          <GridContainer justify='center'>
            <GridItem xs={12} sm={12} md={12} className={classes.center}>
              <Card
                style={{
                  padding: '10px',
                  textAlign: 'center'
                }}
              >
                <CardHeader color='info' icon>
                  <CardIcon color='info'>
                    <h4 style={{ color: 'white' }}>
                      {imgFile.attachmentTitle}
                    </h4>
                  </CardIcon>
                  <Button
                    color='danger'
                    round
                    style={{ float: 'right' }}
                    onClick={() => closeFileViewer()}
                  >
                    Go Back
                  </Button>
                </CardHeader>
                <CardBody>
                  <Box sx={{ mb: 2 }}>
                    <div
                      style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                    >
                      <Typography variant='subtitle1' gutterBottom>
                        File Description :
                      </Typography>
                      <Typography variant='body2' gutterBottom>
                        {imgFile.description}
                      </Typography>
                    </div>
                    <img
                      width='100%'
                      src={`${process.env.REACT_APP_LDOCS_API_URL}/${imgFile.attachmentPath}`}
                      alt=''
                    />
                  </Box>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </Animated>
      ) : (
        ''
      )}
    </>
  )
}
