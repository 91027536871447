import React, { useState } from 'react'
// @material-ui/core components
import { Box, Button, Typography, makeStyles } from '@material-ui/core'

// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'

import { useSelector } from 'react-redux'
import jwt from 'jsonwebtoken'

import 'assets/scss/custome-cards.scss'

// ** asstes
import apImg from '../../../assets/img/icons/ap.png'
import arImg from '../../../assets/img/icons/ar.png'
import accountImg from '../../../assets/img/icons/accountmate_logo.png'
import { Link, useHistory } from 'react-router-dom'
import AuthPermissionsAlert from 'components/AlertBox/AuthPermissionsAlert'

const useStyles = makeStyles(styles)

const Home = () => {
  const classes = useStyles()
  const [optCheck, setoptCheck] = useState(false)

  // ** hook
  const history = useHistory()
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const userData = jwt.decode(Token)
  const permissions = useSelector((state) => state.userReducer.permissions)

  // ** accounts mate userToken & path and thier permissions
  const SendUserToken = (route) => {
    const UrlObject = { selectedRoute: route, Token: Token } // ** contain two objects one selected route for accounts mate and second usertoken
    const TokenData = JSON.stringify(UrlObject)
    window.location.href = `${process.env.REACT_APP_ACCOUNTSMATE_URL}/?token=${TokenData}`
  }

  const handleClick = (route) => {
    if (route === '/GLTable') {
      if (permissions?.accounts?.others?.glEntryForm?.enable === true) {
        SendUserToken(route)
      } else {
        AuthPermissionsAlert()
      }
    } else if (route === '/ChartofAccounts') {
      if (permissions?.accounts?.others?.chartofAccount?.enable === true) {
        SendUserToken(route)
      } else {
        AuthPermissionsAlert()
      }
    } else if (route === '/PaymentVoucher') {
      if (permissions?.accounts?.others?.paymentVoucher?.enable === true) {
        SendUserToken(route)
      } else {
        AuthPermissionsAlert()
      }
    } else if (route === '/DespositVoucher') {
      if (permissions?.accounts?.others?.depositVoucher?.enable === true) {
        SendUserToken(route)
      } else {
        AuthPermissionsAlert()
      }
    }
  }

  function Greeting() {
    const currentHour = new Date().getHours()
    if (currentHour >= 5 && currentHour < 12) {
      return 'Good Morning'
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon'
    } else {
      return 'Good evening'
    }
  }

  return (
    <div className={classes.container}>
      <GridContainer justify='center' spacing={2}>
        <GridItem xs={12}>
          <Box sx={{ mb: '4rem' }}>
            <h4>{Greeting()}</h4>
            <Typography
              variant='h6'
              component='span'
              sx={{ display: 'inline' }}
            >
              Welcome to InvoiceMate,
            </Typography>
            <Typography
              variant='h6'
              component='span'
              sx={{ display: 'inline' }}
            >
              {userData?.role?.isAdmin
                ? ` "${userData?.displayName}"`
                : ` "${userData?.orgDetail?.organization}"`}
            </Typography>
          </Box>
        </GridItem>
        {/* showing only those cards wich user have acess */}
        {userData?.tenantPermissions?.enableAP === true ? (
          <GridItem xs={12} sm={6} md={4}>
            <div className='card' tag={Button}>
              <Link to='/default/dashboard/ap'>
                <span
                  className='icon'
                  // onClick={() => history.push('/default/dashboard/ap')}
                >
                  <div className='icon_size'>
                    <img src={apImg} alt='avatarImg' width={40} />
                  </div>
                </span>
              </Link>
              <h4>Accounts Payable</h4>

              {permissions?.ap?.invoiceDesk?.createInvoice?.enable === true ? (
                <Link underline='hover' to='/default/create/ap'>
                  <p>Create Invoice</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Create Invoice</p>
                </div>
              )}

              {permissions?.ap?.invoiceDesk?.receivedInvoice?.enable ===
              true ? (
                <Link to='/default/invoices/ap'>
                  <p>Manage Invoice</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Manage Invoice</p>
                </div>
              )}
              {permissions?.ap?.invoiceDesk?.receivedInvoice?.enable ===
              true ? (
                <Link to='/default/purchaseOrders/ap'>
                  <p>Purchase Orders</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Purchase Orders</p>
                </div>
              )}

              {permissions?.ap?.others?.reports?.enable === true ? (
                <Link to='/default/receipts/ap'>
                  <p>Receipts</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Receipts</p>
                </div>
              )}

              <div className='shine'></div>
              <div className='background'>
                <div className='tiles'>
                  <div className='tile tile-1'></div>
                  <div className='tile tile-2'></div>
                  <div className='tile tile-3'></div>
                  <div className='tile tile-4'></div>
                  <div className='tile tile-5'></div>
                  <div className='tile tile-6'></div>
                  <div className='tile tile-7'></div>
                  <div className='tile tile-8'></div>
                  <div className='tile tile-9'></div>
                  <div className='tile tile-10'></div>
                </div>
                <div className='line line-1'></div>
                <div className='line line-2'></div>
                <div className='line line-3'></div>
              </div>
            </div>
          </GridItem>
        ) : (
          ''
        )}
        {userData?.tenantPermissions?.enableAR === true ? (
          <GridItem xs={12} sm={6} md={4}>
            <div className='card' tag={Button}>
              <Link to='/default/dashboard/ar'>
                <span
                  className='icon'
                  // onClick={() => history.push('/default/dashboard/ar')}
                >
                  <div className='icon_size'>
                    <img src={arImg} width={40} alt='avatarImg' />
                  </div>
                </span>
              </Link>
              <h4>Accounts Receivable</h4>

              {permissions?.ar?.invoiceDesk?.createInvoice?.enable === true ? (
                <Link to='/default/create/ar'>
                  <p>Create Invoice</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Create Invoice</p>
                </div>
              )}
              {permissions?.ar?.invoiceDesk?.invoices?.enable === true ? (
                <Link to='/default/invoices/ar'>
                  <p>Invoices</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Invoices</p>
                </div>
              )}
              {permissions?.ar?.invoiceDesk?.sendInvoices?.enable === true ? (
                <Link to='/default/send_invoices/ar'>
                  <p>Send Invoices</p>
                </Link>
              ) : (
                <div onClick={() => AuthPermissionsAlert()}>
                  <p>Send Invoices</p>
                </div>
              )}
              <p>{``}</p>
              <div className='shine'></div>
              <div className='background'>
                <div className='tiles'>
                  <div className='tile tile-1'></div>
                  <div className='tile tile-2'></div>
                  <div className='tile tile-3'></div>
                  <div className='tile tile-4'></div>
                  <div className='tile tile-5'></div>
                  <div className='tile tile-6'></div>
                  <div className='tile tile-7'></div>
                  <div className='tile tile-8'></div>
                  <div className='tile tile-9'></div>
                  <div className='tile tile-10'></div>
                </div>
                <div className='line line-1'></div>
                <div className='line line-2'></div>
                <div className='line line-3'></div>
              </div>
            </div>
          </GridItem>
        ) : (
          ''
        )}
        {userData?.tenantPermissions?.enableAccounts === true ? (
          <GridItem xs={12} sm={6} md={4}>
            <div className='card' tag={Button} onClick={handleClick}>
              <span className='icon'>
                <img src={accountImg} height={65} width={65} alt='avatarImg' />
              </span>
              <h4>Accounts Mate</h4>
              <div onClick={() => handleClick('/GLTable')}>
                <p>GL Entry</p>
              </div>
              <div onClick={() => handleClick('/ChartofAccounts')}>
                <p>Chart of Accounts</p>
              </div>
              <div onClick={() => handleClick('/PaymentVoucher')}>
                <p>Payment Voucher</p>
              </div>
              <div onClick={() => handleClick('/DespositVoucher')}>
                <p>Deposit Voucher</p>
              </div>
              <div className='shine'></div>
              <div className='background'>
                <div className='tiles'>
                  <div className='tile tile-1'></div>
                  <div className='tile tile-2'></div>
                  <div className='tile tile-3'></div>
                  <div className='tile tile-4'></div>
                  <div className='tile tile-5'></div>
                  <div className='tile tile-6'></div>
                  <div className='tile tile-7'></div>
                  <div className='tile tile-8'></div>
                  <div className='tile tile-9'></div>
                  <div className='tile tile-10'></div>
                </div>
                <div className='line line-1'></div>
                <div className='line line-2'></div>
                <div className='line line-3'></div>
              </div>
            </div>
          </GridItem>
        ) : (
          ''
        )}
      </GridContainer>
    </div>
  )
}

export default Home
