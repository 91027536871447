export const defaultCurrency = {
    _id: "602a2d12f31966419864e43c",
    lookup_Id: 1,
    Code: "USD",
    Symbol: "$",
  };
  export const VendorSites = [
    " Jeddah, Saudi Arabia",
    " Ad-Dilam, Saudi Arabia",
    " Al-Abwa, Saudi Arabia",
    " Abha, Saudi Arabia",
  ];