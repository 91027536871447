import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import ReactTable from 'react-table'
// @material-ui/core components
import {
  makeStyles,
  CircularProgress,
  LinearProgress,
  Slide,
  Dialog,
  DialogContent,
  Tooltip,
  IconButton,
  Badge,
  withStyles,
  Typography,
  SwipeableDrawer,
  Chip
} from '@material-ui/core'
// core components
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardIcon from 'components/Card/CardIcon.js'
import CardHeader from 'components/Card/CardHeader.js'
import axios from 'axios'
import RefreshIcon from '@material-ui/icons/Refresh'
import { cardTitle } from 'assets/jss/material-dashboard-pro-react.js'
import { Animated } from 'react-animated-css'
import { useDispatch, useSelector } from 'react-redux'
import jwt from 'jsonwebtoken'
import {
  addZeroes,
  currentTracking,
  formatDate,
  formatDateTime
} from 'views/LDocs/Functions/Functions'
import 'react-contexify/dist/ReactContexify.min.css'
import Swal from 'sweetalert2'
import {
  successAlert,
  errorAlert,
  msgAlert
} from 'views/LDocs/Functions/Functions'
import CreatePO from './createPo'
import moment from 'moment'
import { setIsTokenExpired } from 'actions'
import { EditOutlined, Visibility } from '@material-ui/icons'

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardTitleText: {
    color: 'white'
  }
}

const useStyles = makeStyles(styles)

const PurchaseOrders = () => {
  const classes = useStyles()
  const history = useHistory()

  const isAr =
    history.location.pathname.substring(
      history.location.pathname.lastIndexOf('/') + 1
    ) == 'ar'
      ? true
      : false

  const dispatch = useDispatch()
  const Token =
    useSelector((state) => state.userReducer.Token) ||
    localStorage.getItem('im-mate')
  const userDetails = jwt.decode(Token)

  const [isLoading, setIsLoading] = React.useState(true)
  const [createPO, setCreatePO] = React.useState(false)
  const [data, setData] = React.useState([])
  const [editRowData, setEditRowData] = React.useState([])

  const [supplierName, setSupplierName] = useState([])
  const [formState, setFormState] = useState({
    issueDate: moment(new Date()).format('YYYY-MM-DD'),
    expiryDate: '',
    poAmount: '',
    paymentTerms: '',
    partialDelivery: '',
    supplier: '',
    poNumber: ''
  })

  useEffect(() => {
    getData()
    getAllSuppliers()
  }, [])

  const handleClose = () => {
    setCreatePO(false)
    setFormState((formState) => ({
      issueDate: '',
      expiryDate: '',
      poAmount: '',
      paymentTerms: '',
      partialDelivery: '',
      supplier: '',
      poNumber: ''
    }))
  }

  const getAllSuppliers = async (is_ar) => {
    try {
      const response = await axios({
        method: 'get',
        url: is_ar
          ? `${process.env.REACT_APP_LDOCS_API_URL}/AR/clientByOrganization/${userDetails.orgDetail.organizationId}`
          : `${process.env.REACT_APP_LDOCS_API_URL}/vendor/vendorsByOrganization/${userDetails.orgDetail.organizationId}`,
        headers: { cooljwt: Token }
      })

      const data = await response.data
      setSupplierName(data)
    } catch (error) {
      if (error.response) {
        error.response.status === 401 && dispatch(setIsTokenExpired(true))
      }
      console.log(error)
    }
  }

  const getData = async () => {
    await axios({
      method: 'get',
      url: `${process.env.REACT_APP_LDOCS_API_URL}/po/getAllPoc`,
      headers: { cooljwt: Token }
    })
      .then(async (response) => {
        const data = await response.data
        console.log(data)
        setData(
          data.map((item, index) => ({
            id: index,
            dateofExpiry: formatDateTime(item?.dateOfExpiry),
            dateofIssue: formatDateTime(item?.dateOfIssue),
            PartialDelivery: item?.partialDelivery ? 'Yes' : 'No',
            poNumber: item?.poNumber,
            supplier: item?.vendorName,
            poAmount: addZeroes(item?.poAmount),
            PaymentTerms: item?.paymentTerm,
            actions: (
              <div className='actions-left'>
                <Tooltip title={'Edit'} aria-label='received'>
                  <Button
                    justIcon
                    round
                    simple
                    icon={EditOutlined}
                    onClick={() => {
                      setEditRowData(item)
                      setCreatePO(true)
                    }}
                    color='info'
                    className='Edit'
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
              </div>
            )
          }))
        )
        setIsLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          error.response.status == 401 && dispatch(setIsTokenExpired(true))
        }
        console.log(error)
      })
  }

  return (
    <div>
      {createPO && (
        <CreatePO
          getData={getData}
          formState={formState}
          setFormState={setFormState}
          createPO={createPO}
          setCreatePO={setCreatePO}
          handleClose={handleClose}
          supplierName={supplierName}
          editSingleRecord={editRowData}
        />
      )}
      <Animated
        animationIn='bounceInRight'
        animationOut='bounceOutLeft'
        animationInDuration={1000}
        animationOutDuration={1000}
        isVisible={true}
      >
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader color='info' icon>
                <CardIcon color='info'>
                  <h4 className={classes.cardTitleText}>Purchase Orders</h4>
                </CardIcon>
                <Button
                  color='danger'
                  round
                  style={{ float: 'right' }}
                  className={classes.marginRight}
                  onClick={() => {
                    setEditRowData([])
                    setCreatePO(true)
                  }}
                >
                  Add PO
                </Button>
              </CardHeader>
              <CardBody>
                {isLoading ? (
                  <LinearProgress />
                ) : (
                  <ReactTable
                    data={data}
                    sortable={false}
                    columns={[
                      {
                        Header: 'PO Number',
                        accessor: 'poNumber'
                      },
                      {
                        Header: 'Vendor Name',
                        accessor: 'supplier'
                      },
                      {
                        Header: 'PO Amount',
                        accessor: 'poAmount'
                      },
                      {
                        Header: 'Payment Terms',
                        accessor: 'PaymentTerms'
                      },
                      {
                        Header: 'Partial Delivery',
                        accessor: 'PartialDelivery'
                      },
                      {
                        Header: 'Date of Issue',
                        accessor: 'dateofIssue'
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'left'
                            }}
                          >
                            Date of Expiry
                          </div>
                        ),
                        accessor: 'dateofExpiry'
                      },
                      {
                        Header: () => (
                          <div
                            style={{
                              textAlign: 'left'
                            }}
                          >
                            Actions
                          </div>
                        ),
                        accessor: 'actions',
                        filterable: false
                      }
                    ]}
                    defaultPageSize={10}
                    showPaginationTop
                    showPaginationBottom={true}
                    className='-striped -highlight'
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Animated>
    </div>
  )
}

export default PurchaseOrders
